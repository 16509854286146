import { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  ProgressBar,
  Modal,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import NftBuyAbi from "../common/NftBuyAbi.json";
import ConsolidatedPredictionAbi from '../common/ConsolidatedPredictionAbi.json'

import {
  Handleconnect,
  formatChainAsNum,
  formatAddress,
  formatBalance,
  formatBalanceWithDecimals,
  getEstimateGasFee,
} from "../common/connectWallet";
import { ethers } from "ethers";
import { useParams, useNavigate } from "react-router-dom";
import { apiService } from "../service/api.service";
import { contractAbi as PredictionAbi, contractAbiPlatformFee } from "../config/contractAbi";
import { contractOldAbi } from "../config/contractOldAbi";
import { tokenAbi } from "../config/tokenAbi";
import { betCounterAbi } from "../config/betCounterAbi";
import { erc721Abi } from "../config/erc721Abi";
import { discountAbi } from "../config/discountAbi";
import { adConfig, mintNftsList, multiTimeExtensionNFT_01_BETS_ADDRESS, multiTimeExtensionNFT_01_MAX_BETS, multiTimeExtensionNFT_20_BETS_ADDRESS, multiTimeExtensionNFT_20_MAX_BETS, NftBuyContractAddress, showCheckout, soloTimeExtensionNFT_01_BETS_ADDRESS, soloTimeExtensionNFT_01_MAX_BETS, soloTimeExtensionNFT_03_BETS_ADDRESS, soloTimeExtensionNFT_03_MAX_BETS, soloTimeExtensionNFT_10_BETS_ADDRESS, soloTimeExtensionNFT_10_MAX_BETS, timeExtenderAddress, discountContract, folioContractAddress } from "../config/config"
import {
  chainId,
  tokenAddress,
  baseUrl,
  gasFeePercent,
  divideBy,
  decimalTo,
  commissionArray,
  betCounterAddress,
  capitalizeFirstLetter
} from "../config/config";
import swal from "sweetalert";
import {
  setWalletAddress,
  setwalletConnected,
  setwalletBalance,
} from "../store/index_slice";
import { useSelector, useDispatch } from "react-redux";
import NotConnectWalletPopup from "../common/NotConnectWalletPopup";
import moment from "moment";
import Countdown from "react-countdown";
import { ThreeDotSpinner, InlineLoader } from "./loader/index";
import { PieChart } from "@mui/x-charts/PieChart";
import SanitizeHTML from "./SanitizeHTML"
import RiskFreeMessages from "./RiskFreeMessages";
import SoloTypeTooltip from './SoloTypeTooltip';
import { adClient } from '../common/adConfig'
import { timeExtenderAbi } from "../config/timeExtenderAbi";
import { WEB3_PROVIDER } from "../config/config"
import SubscribeForm from "../common/SubscribeForm";
import NftMint from "../common/NftMint";
import AdComponent from "../common/AdComponent";
import { FundAddModal } from "../common/FundAddModal";



const { Web3 } = require("web3");
const PredictionDetail = () => {
  const [showPlaceBetSteps, setShowPlaceBetSteps] = useState(false);
  const [showPlaceBetRiskFreeSteps, setShowPlaceBetRiskFreeSteps] = useState(false);
  let contractAbi = PredictionAbi;


  const handleClosePlaceBetSteps = () => setShowPlaceBetSteps(false);
  const handleClosePlaceBetRiskFreeSteps = () => setShowPlaceBetRiskFreeSteps(false);
  const handleShowPlaceBetSteps = () => setShowPlaceBetSteps(true);
  const handleShowPlaceBetRiskFreeSteps = () => setShowPlaceBetRiskFreeSteps(true);

  const [price, setPrice] = useState();

  const [contractAddress, setcontractAddress] = useState();
  const dispatch = useDispatch();
  const [truePer, settruePer] = useState();
  const [falsePer, setfalsPer] = useState();
  const [payoutprediction, setpayoutprediction] = useState(0);
  const [payoutRiskfreeprediction, setPayoutRiskfreePrediction] = useState(0);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [madebid, setmadebid] = useState(false);
  const [bidbtn, setbidbtn] = useState(false);
  const [comission, setcommision] = useState();
  const [betEndTime, setbetEndTime] = useState();
  const [extensionTime, setextensionTime] = useState();
  const [tokenApproved, settokenApproved] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [symbolsArr] = useState([
    " ",
    "`",
    "~",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",
    "-",
    "=",
    "+",
    "{",
    "}",
    "[",
    "]",
    "'",
    "|",
    ";",
    ":",
    '"',
    "?",
    "/",
    "<",
    ">",
    ",",
    "\\",
    "-",
  ]);
  const [prediction, setprediction] = useState();
  const [mintNftIndex, setMintNftIndex] = useState(null);
  const [predictionCount, setpredictionCount] = useState();
  const [leaderboard, setleaderboard] = useState();
  const [priceErr, setpriceErr] = useState("");
  const [limit, setlimit] = useState(4);
  const [platformFee, setplatforFee] = useState();
  const [is_walletConnected, setis_walletConnected] = useState(localStorage.getItem("is_walletConnected"));
  const [connected_wallet, setconnected_wallet] = useState(localStorage.getItem("connected_wallet"))
  const iswallet = useSelector((state) => state.reducer.walletConnected);
  const walletAddress = useSelector((state) => state.reducer.walletAddress);
  const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
  const walletBalance = useSelector((state) => state.reducer.walletBalance);
  // const { dataProvider, address } = useParams();
  const address = useParams();
  const [prdictionUrlAddress, questionUrl] = address.address.split("_");
  const [mintedNft, setMintedNft] = useState();
  const [pieChartData, setPieChartData] = useState([]);
  const [countArray, setCountArray] = useState([]);
  const [amountArray, setAmountArray] = useState([]);
  const [requiredBetFolioNft, setRequiredBetFolioNft] = useState();
  const [erc721Balance, setErc721Balance] = useState()
  const [eRC20TokenBalance, setErc20TokenBalance] = useState()

  const [allLeadBoardList, setAllLeadBoardList] = useState([]);
  const [answer, setanswer] = useState();
  const [isTimeRemaningForBet, setIsTimeRemaningForBet] = useState(false);
  const [isExtendedNft, setIsExtendedNft] = useState(false);
  const [extendedNftRemaning, setExtendedNftRemaning] = useState(0);
  const [totalExtensionNFTBalance, setTotalExtensionNFTBalance] = useState(0)
  const extensionType = window.location.pathname.split("/")[1];

  useEffect(() => {
    if (!walletAddress?.accounts && walletAddress && web3AuthProvider) {
      extensionNFTBalances();
    }
  }, [web3AuthProvider, walletAddress]);

  useEffect(() => {
    if (!walletAddress?.accounts && walletAddress && web3AuthProvider && address?.address) {
      getPastEvents();
    } else {
      get_predictionDetal(address);
    }
  }, [web3AuthProvider, walletAddress, address?.address])

  useEffect(() => {
    // Check if the page is reloaded already
    const isReloaded = localStorage.getItem("isReloaded");
    if (!isReloaded) {
      localStorage.setItem("isReloaded", "true"); // Set the flag in localStorage
      window.location.reload(); // Reload the page
    }

    return () => {
      // Cleanup: Remove the flag when leaving the page
      localStorage.removeItem("isReloaded");
    };
  }, [])


  async function getPastEvents() {
    try {
      const web3 = new Web3(web3AuthProvider);
      contractAbi = Number(prediction?.platformFee) && Number(prediction?.platformFee) > 0 ? contractAbiPlatformFee : PredictionAbi;
      const contractMethods = new web3.eth.Contract(contractAbi, address?.address);
      const events = await contractMethods.getPastEvents('PredictionMade', {
        filter: { user: walletAddress }, // Filters by your address
        fromBlock: 0, // Start from the genesis block, or specify a block number
        toBlock: 'latest' // Fetch until the latest block
      });

      for await (const event of events) {
        const { blockNumber } = event;
        checkBet(Number(blockNumber), address?.address)
      }
      if (events?.length > 0) {

        setTimeout(() => {
          get_predictionDetal(address);
        }, 2000)
      } else {
        get_predictionDetal(address);
      }
    } catch (error) {
      console.error('Error fetching past events:', error);
    }
  }


  const extensionNFTBalances = async () => {
    // console.log("pathname------------------->",pathname.split("/")[1]);
    const web3 = new Web3(web3AuthProvider);
    const wallet = walletAddress;
    let totalExtensionNFT = 0;
    if (extensionType == "solo-predictions") {
      const soloTimeExtensionNFT_01_BETSContract = new web3.eth.Contract(tokenAbi, soloTimeExtensionNFT_01_BETS_ADDRESS);
      const extensionNFT01_BETSBalance = await soloTimeExtensionNFT_01_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT = Number(extensionNFT01_BETSBalance) * soloTimeExtensionNFT_01_MAX_BETS;
      const soloTimeExtensionNFT_03_BETSContract = new web3.eth.Contract(tokenAbi, soloTimeExtensionNFT_03_BETS_ADDRESS);
      const extensionNFT03_BETSBalance = await soloTimeExtensionNFT_03_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT += Number(extensionNFT03_BETSBalance) * soloTimeExtensionNFT_03_MAX_BETS;
      const soloTimeExtensionNFT_10_BETSContract = new web3.eth.Contract(tokenAbi, soloTimeExtensionNFT_10_BETS_ADDRESS);
      const extensionNFT10_BETSBalance = await soloTimeExtensionNFT_10_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT += Number(extensionNFT10_BETSBalance) * soloTimeExtensionNFT_10_MAX_BETS;
    } else if (extensionType == "multi-choice-predictions") {
      const multiTimeExtensionNFT_01_BETSContract = new web3.eth.Contract(tokenAbi, multiTimeExtensionNFT_01_BETS_ADDRESS);
      const extensionNFT01_BETSBalance = await multiTimeExtensionNFT_01_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT = Number(extensionNFT01_BETSBalance) * multiTimeExtensionNFT_01_MAX_BETS;

      const multiTimeExtensionNFT_20_BETSContract = new web3.eth.Contract(tokenAbi, multiTimeExtensionNFT_20_BETS_ADDRESS);
      const extensionNFT20_BETSBalance = await multiTimeExtensionNFT_20_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT += Number(extensionNFT20_BETSBalance) * multiTimeExtensionNFT_20_MAX_BETS;
    }
    console.log("totalExtensionNFT", totalExtensionNFT);
    setTotalExtensionNFTBalance(totalExtensionNFT)
  }



  const navigate = useNavigate();

  useEffect(() => {
    console.log("walletAddress tu>>>>>>>", walletAddress);


  }, []);

  /* detail page add of persona */

  // adClient.showBannerAd({
  //   adUnitId: "d919e82a-58b8-485c-8067-552e965015af", // Live
  //   // adUnitId: "1ce8e2fa-4ab0-4f42-b339-ebbaaee1e3cf", // Test
  //   containerId: "ads-detail-page"
  // }, (errorMessage) => {
  //   console.log("errorMessage", errorMessage);
  //   // You can handle error here.
  // })

  // adClient.showBannerAd({
  //   adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d", // live
  //   // adUnitId: "23a5da96-5f02-4884-b4f9-8ca6a5bc95d7", // testing
  //   containerId: "ads-banner-top"
  // }, (errorMessage) => {
  //   console.log("errorMessage::::::::::", errorMessage);
  //   // You can handle error here.
  // })

  /* detail page follow step add of persona */

  // useEffect(() => {
  //   adClient.showBannerAd({
  //     adUnitId: "2e4a94fa-c9c8-47a4-891a-8e99306a5653", // Live
  //     // adUnitId: "24fc3e1c-ce25-456c-8d84-4fe1dc8646f3", // Test
  //     containerId: "ads-follow-step"
  //   }, (errorMessage) => {
  //     console.log("errorMessage", errorMessage);
  //     // You can handle error here.
  //   })
  // }, [showPlaceBetSteps, showPlaceBetRiskFreeSteps]);


  // extensionTime
  useEffect(() => {
    setIsTimeRemaningForBet(extensionTime > new Date())
  }, [extensionTime]);

  async function isAlreadyBet(prediction_contract, user_wallet) {

    let data = await checkprediction(prediction_contract, user_wallet);
    if (data !== null) {
      setmadebid(true);
    }
  }

  useEffect(() => {
    const fetchRemainingBets = async () => {
      if (!connected_wallet || !prediction?.data_provider) return;
      try {

        isAlreadyBet(prediction.contract_address, connected_wallet);

        const web3 = new Web3(WEB3_PROVIDER);
        const contract = new web3.eth.Contract(timeExtenderAbi, timeExtenderAddress);
        console.log("timeExtenderAddress--->", timeExtenderAddress);


        const method = prediction.data_provider === "solo-predictions"
          ? "remainingSoloBets"
          : prediction.data_provider === "multi-choice-predictions"
            ? "remainingMultiBets"
            : null;

        console.log("method", method);


        if (!method) return; // Exit if no valid data_provider

        // Call the appropriate method
        console.log("method is ", method);
        const remainingBets = await contract.methods[method](connected_wallet).call();
        console.log("remainingBets is", remainingBets, connected_wallet)

        // Update state based on the count
        setIsExtendedNft(Number(remainingBets) > 0);
        setExtendedNftRemaning(Number(remainingBets));
      } catch (error) {
        console.log('Error fetching remaining bets:', error);
      }
    };
    fetchRemainingBets();
  }, [connected_wallet, prediction]);


  useEffect(() => {
    if (!prediction?.extendedTime) return;
    let endDateTime = moment(prediction.endDateTime);
    let extendedTimeMinutes = prediction.extendedTime || 0;
    let extendedTime = endDateTime.add(extendedTimeMinutes, 'minutes');
    let extendedBetTime = moment(extendedTime).subtract(
      prediction.threshold_time,
      "minutes"
    );
    setextensionTime(extendedBetTime);
  }, [prediction]);


  useEffect(() => {
    let contractAddress = address && address.address ? address.address : ''
    if (leaderboard && contractAddress) {
      getAllLeadboardList(contractAddress, leaderboard.leaderboardTotalCount);
    }
  }, [leaderboard]);


  useEffect(() => {
    if (answer && answer.length > 0) {
      handlePayoutRiskfreePrediction()
    }
  }, [answer]);



  const checkConnection = () => {
    let webb3 = new Web3(window.ethereum);
    if (localStorage.getItem("user_type") == "CUSTODIAL") {
      webb3 = new Web3(web3AuthProvider);

    }

    let walletConnected = true;
    return webb3.eth.getAccounts().then(async (addr) => {
      if (addr.length == 0) {
        localStorage.clear();
        handleShow();
        walletConnected = false;
      } else {
        console.log(addr[0], "!==", localStorage.getItem("connected_wallet"));
        if (
          addr[0].toLowerCase() !==
          localStorage.getItem("connected_wallet").toLowerCase()
        ) {
          setmadebid(false);
          setbidbtn(false);
          swal(
            "Warning",
            "You've linked to a different wallet address. Kindly verify in MetaMask.",
            "warning"
          );
          walletConnected = false;
        } else {

          walletConnected = true;
        }
      }
      return walletConnected;
    });
  };


  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // setmadebid(true);
      return <span>Closed</span>;
    } else {
      return (
        <span>
          {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}
        </span>
      );
    }
  };

  // I am here
  async function leaderboardDetail(address, lmt) {
    try {
      const response = await apiService.leaderboardDetail(address, lmt);
      if (response.status == 200) {
        setleaderboard(response.data.data);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  };


  async function getAllLeadboardList(address, lmt) {
    try {
      const response = await apiService.leaderboardDetail(address, lmt);
      if (response.status == 200) {
        let list = response && response.data && response.data.data && response.data.data.leaderboard ? response.data.data.leaderboard : []

        for (let i in list) {
          let prediction = list[i].prediction

          if (typeof prediction == "string") {
            prediction = prediction
          } else {
            prediction = prediction.toString()
          }

          let arr = list[i].prediction.split(",");
          list[i].prediction = arr
        }

        setAllLeadBoardList(list);
      }
    }
    catch (error) {
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else;
    }
  };


  async function checkprediction(address, walletAddress) {
    try {
      const response = await apiService.checkprediction(address, walletAddress);
      if (response.status == 200) {
        console.log("=====bet===", response.data.data);
        return response.data.data;
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  };


  async function get_count_according_to_access_setting() {
    if (prediction.access_setting < 8) {
      return 1
    }

    if (prediction.access_setting == 8 || prediction.access_setting == 9) {
      return 3
    }

    if (prediction.access_setting == 10) {
      return 4
    }


  }

  function pieChartDataCreation(resultArray) {
    const resultArrayWithBets = [];
    for (const item of resultArray) {
      if (item.value !== 0 && item.value !== "0") {
        resultArrayWithBets.push(item);
      }
    }
    setPieChartData(resultArrayWithBets);
  };


  async function get_predictionDetal(address) {
    setLoader(false);
    try {
      const response = await apiService.get_predictionDetail(address.address);
      if (response.status == 200) {
        // if (response?.data?.data?.data_provider != address.dataProvider) {
        //   navigate(`/${response?.data?.data?.isConsolidated ? "consolidated" : response?.data?.data?.data_provider}/${address.address}`);
        // }

        console.log("get_predictionDetal____>>", response?.data);
        leaderboardDetail(address.address, limit);
        moment(response.data.data.endDateTime);

        let predictionEndTime = moment(response.data.data.endDateTime);

        // Parse threshold_time as an integer, if necessary
        let thresholdTime = parseInt(response.data.data.threshold_time, 10);

        // Subtract minutes
        let betTime = predictionEndTime.subtract(thresholdTime, "minutes");

        console.log("betTime>>>>", new Date(betTime.format()), betTime, thresholdTime); // Format betTime to see the output

        setbetEndTime(betTime); // Ensure setbetEndTime works with the moment object



        setprediction(response.data.data);
        console.log("response.data.data>>>>>", response.data.data);
        let remaining = response.data.data.timeRemaining.split(" ");
        setpredictionCount(response.data.countsAndAmounts);
        if (parseInt(remaining[0]) <= 0) {
          setmadebid(true);
          setbidbtn(true);
        }

        let tmpAns = response?.data?.data?.isBetOnPrediction
          ? response.data.data.userOutcome
          : response.data.data.answer_option[0];
        if (response.data.data.access_setting == 8 || response.data.data.access_setting == 9 || response.data.data.access_setting == 10) {
          setanswer([]);
        } else {
          setanswer([tmpAns]);
        }

        const { countArrayTmp, amountArrayTmp } = createArrayFromData(
          response?.data?.data.answer_option.length,
          response?.data?.countsAndAmounts
        );

        console.log("countArrayTmp", countArrayTmp, amountArrayTmp)
        setCountArray(countArrayTmp);
        setAmountArray(amountArrayTmp);

        if (response.data.data.userAmount > 0) {
          // case if i already bet on this prediction :::::: PAID prediction
          let priceadded = formatBalance(response.data.data.userAmount);
          setPrice(priceadded);
          let answer_index = response.data?.data?.answer_option?.indexOf(
            tmpAns
          );

          const otherPoolSum = sumExceptAtIndex(countArrayTmp, answer_index);

          payoutpridict(priceadded, amountArrayTmp[answer_index], otherPoolSum);
          var commission;
          commissionArray.map((data, index) => {
            if (priceadded >= data.amountmin && priceadded <= data.amountmax) {
              commission = data.commission;
              commission = (data.commission / 100) * priceadded;
              setplatforFee(commission.toFixed(2));
            }
          });
          console.log("here i am >>>>>>>>>>>>>>");
          setmadebid(true);
          setbidbtn(true);
          // setanswer(response.data.data.userOutcome);
        }

        if (response?.data?.data?.prediction_type === "RISK-FREE") {
          console.log("PayoutRiskFree response.data.data", response.data);
          let answer_index = response.data.data.answer_option?.indexOf(tmpAns);
          const otherPoolCount = sumExceptAtIndex(countArray, answer_index)
          console.log("riskfree>>>>>>", response.data, tmpAns)
          if (response.data.data.access_setting == 8 || response.data.data.access_setting == 9 || response.data.data.access_setting == 10) {
            PayoutRiskFree(
              response.data.data.amount,
              0,
              otherPoolCount,
              tmpAns,
              response.data.data.decimal
            );


          } else {
            PayoutRiskFree(
              response.data.data.amount,
              countArrayTmp[answer_index],
              otherPoolCount,
              tmpAns,
              response.data.data.decimal
            );


          }


          getpercentageriskFree(
            response?.data?.countsAndAmounts?.trueCount,
            response?.data?.countsAndAmounts?.falseCount
          );
          const counts = response?.data?.data?.answer_option?.map(
            (_, index) => {
              const key = `count_${index}`;
              return response?.data?.countsAndAmounts[key] ? response?.data?.countsAndAmounts[key] : 0;
            }
          );
          console.log("counts::::::::::::", counts, response?.data?.countsAndAmounts);
          const resultArray = response?.data?.data?.answer_option?.map(
            (label, index) => ({
              id: index,
              value: counts[index],
              label: label,
            })
          );
          console.log("resultArray::::::::::", resultArray);
          pieChartDataCreation(resultArray);
          // This snippet is for show progress bar for regular prediction in RISK free.
          // Regular only contain two option i.e true and false so we use static count as admin add the amount
          //
          if (response?.data?.data?.prediction_category == "REGULAR") {
            getpercentage(
              response.data.countsAndAmounts.count_0,
              response.data.countsAndAmounts.count_1
            );
          }
        }
        if (response?.data?.data?.prediction_type === "PAID") {
          // ***********    PAID  prediction ******************* //
          // Manuplating piechart data here
          const amounts = response?.data?.data?.answer_option?.map(
            (_, index) => {
              const key = `amount_${index}`;
              return response?.data?.countsAndAmounts[key];
            }
          );
          const resultArray = response?.data?.data?.answer_option?.map(
            (label, index) => ({
              id: index,
              value: formatBalanceWithDecimals(
                amounts[index],
                response?.data?.data?.decimal
              ),
              label: label,
            })
          );
          pieChartDataCreation(resultArray);


          // This snippet is for show progress bar for regular prediction.
          // Regular only contain two option i.e true and false
          if (response?.data?.data?.prediction_category == "REGULAR") {
            getpercentage(
              response.data.countsAndAmounts.amount_0,
              response.data.countsAndAmounts.amount_1
            );
          }
        }
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  };


  function createArrayFromData(ary_size, obj) {
    console.log("here i am 66", ary_size, obj);
    const countArrayTmp = [];
    const amountArrayTmp = [];
    for (let i = 0; i < ary_size; i++) {
      amountArrayTmp.push(obj[`amount_${i}`]);
      countArrayTmp.push(obj[`count_${i}`]);
    }
    console.log("here i am 67", countArrayTmp, amountArrayTmp);
    return { countArrayTmp, amountArrayTmp };
  };





  try {
    window.ethereum.on("accountsChanged", async () => {
      get_predictionDetal(address);
    });
    window.ethereum.on("disconnect", async () => {
      dispatch(setwalletConnected(false));
      dispatch(setWalletAddress({ accounts: [] }));
      localStorage.clear();
    });
  } catch (error) { }


  function payoutpridictNew(
    amtPredicted,
    _totalAmtRightPredictedWei,
    _totalAmtWrongPredictedWei
  ) { }


  function payoutpridict(
    amtPredicted,
    _totalAmtRightPredictedWei,
    _totalAmtWrongPredictedWei
  ) {
    console.log(
      "tushar amtPredicted",
      amtPredicted,
      _totalAmtRightPredictedWei,
      _totalAmtWrongPredictedWei
    );
    let amtPredictedWei = amtPredicted * divideBy;
    if (_totalAmtRightPredictedWei === 0) {
      let payoutWei = amtPredictedWei + _totalAmtWrongPredictedWei;
      setpayoutprediction(parseFloat(formatBalance(payoutWei)));
    } else {
      let payoutWei =
        amtPredictedWei +
        (amtPredictedWei / (_totalAmtRightPredictedWei + amtPredictedWei)) *
        _totalAmtWrongPredictedWei;
      setpayoutprediction(parseFloat(formatBalance(payoutWei)));
    }
  };

  function sortCommaSeparatedString(input) {
    console.log("input>>>>", input)
    if (input == false) {
      return input;
    }
    let array = input.split(',');
    array = array.map(element => element.trim());
    array.sort();
    let sortedString = array.join(', ');
    return sortedString;
  }


  function rightAnswer(pred) {
    if (pred.access_setting == 8) {
      return sortCommaSeparatedString(pred?.result) == sortCommaSeparatedString(pred?.userOutcome)
    } else {
      return pred?.result == pred?.userOutcome
    }
  }

  function PayoutRiskFree(
    amtPredicted,
    _totalAmtTruePredictedWei,
    _totalAmtFalsePredictedWei,
    valueSelected,
    tokenDecimals
  ) {
    console.log(
      "PayoutRiskFree=======",
      amtPredicted,
      _totalAmtTruePredictedWei,
      _totalAmtFalsePredictedWei,
      valueSelected
    );
    let totalPredictedCount = valueSelected
      ? _totalAmtTruePredictedWei + 1
      : _totalAmtFalsePredictedWei + 1;
    let riskFreePayout = amtPredicted / totalPredictedCount;
    console.log("prediction--------", prediction, prediction?.decimal);
    setPayoutRiskfreePrediction(
      parseFloat(formatBalanceWithDecimals(riskFreePayout, tokenDecimals))
    );
  };


  function retieveAmountFrompredictionCount(index) {
    return predictionCount[`amount_${index}`];
  }


  async function pricePrediction(pp) {
    setPrice(pp);
    setpriceErr("");
    var commission = (1 / 100) * pp;

    console.log("amount try to bid", pp);
    console.log("answer", answer);
    console.log("predictionCount", predictionCount);
    console.log("prediction", prediction);

    let answer_index = prediction?.answer_option?.indexOf(answer);
    // retieveAmountFrompredictionCount

    // const otherPoolSum = amountArray.reduce(
    //   (accumulator, currentValue, currentIndex) => {
    //     if (currentIndex === answer_index) {
    //       return accumulator + currentValue;
    //     } else {
    //       return accumulator;
    //     }
    //   },
    //   0
    // );
    const otherPoolSum = sumExceptAtIndex(amountArray, answer_index);

    console.log("varsha>>>>>", amountArray, answer_index, amountArray[answer_index], otherPoolSum)
    if (answer_index > -1) {
      payoutpridict(pp, amountArray[answer_index], otherPoolSum);
    } else {
      payoutpridict(pp, 0, otherPoolSum);
    }

    var commission;

    commissionArray.map((data, index) => {
      console.log("====pp====", pp, ">=", data.amountmin);
      console.log("====pp====", pp, ">=", data.amountmax);
      if (pp >= data.amountmin && pp <= data.amountmax) {
        setcommision(data.commission);
        commission = (data.commission / 100) * pp;
        setplatforFee(commission.toFixed(2));
      }
    });
  };


  const isiPhoneWithSafari = () => {
    const isSafari = /safari/.test(navigator.userAgent.toLowerCase());
    const isTouchDevice =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
    return isSafari && isTouchDevice;
  };


  function sumExceptAtIndex(arr, indices) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      if (indices !== i) {
        sum += arr[i];
      }
    }
    return sum;
  };


  const anserpredict = (value) => {
    let access_setting = prediction.access_setting;

    if ((prediction.access_setting == 8 || prediction.access_setting == 9)) {
      setanswer((prevAnswer) => {
        if (prevAnswer.includes(value)) {
          // Remove the option if it is already in the array
          return prevAnswer.filter(item => item !== value);
        } else {
          console.log("bhatia>>>>")
          if (prevAnswer.length < 3) {
            // Add the option if it is not in the array
            return [...prevAnswer, value];
          } else {
            return [...prevAnswer];
          }
        }
      });
    }
    else if (prediction.access_setting == 10) {
      setanswer((prevAnswer) => {
        if (prevAnswer.includes(value)) {
          // Remove the option if it is already in the array
          return prevAnswer.filter(item => item !== value);
        } else {
          if (prevAnswer.length < 4) {
            // Add the option if it is not in the array
            return [...prevAnswer, value];
          } else {
            return [...prevAnswer];
          }
        }
      });
    }
    else {
      setanswer([value])
      // console.log("updateans=======single", value, access_setting);
      // setanswer(value);
    };

    if (prediction?.prediction_type === "PAID") {
      let answer_index = prediction?.answer_option?.indexOf(value);
      const otherPoolSum = sumExceptAtIndex(amountArray, answer_index);
      console.log(" anserpredict >>>>> answer_index,price, amountArray[answer_index], otherPoolSum", answer_index, price, amountArray[answer_index], otherPoolSum);
      payoutpridict(price, amountArray[answer_index], otherPoolSum);
    }


    if (prediction?.prediction_type === "RISK-FREE") {
      console.log("PayoutRiskFree======= anserpredict1", value, predictionCount);

      if (prediction.access_setting < 8) {
        let answer_index = prediction?.answer_option?.indexOf(value);
        const otherPoolCount = sumExceptAtIndex(countArray, answer_index);

        console.log("anserpredict", prediction?.amount, countArray[answer_index], otherPoolCount)

        PayoutRiskFree(
          prediction?.amount,
          countArray[answer_index],
          otherPoolCount,
          value,
          prediction?.decimal
        );
      }
      // else if(prediction.access_setting == 8){
      //   console.log("predictionCount is>>>>>>" , answer  ,predictionCount?.multiplecountResult)

      // }
      // else if(prediction.access_setting == 9){

      // }
      // else if(prediction.access_setting == 10){

      // }





    }
  };

  function getCountMultipleAnswerMatch(data, searchString) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === searchString) {
        return data[i].count;
      }
    }
    return 0; // If no match is found
  }

  const handlePayoutRiskfreePrediction = () => {
    let predictedAnswerArray = [...answer]
    if (prediction?.access_setting == 8) {
      if (answer?.length == 3) {
        console.log("answer selecteddddd all so calculate payout", answer);
        console.log("answer selecteddddd count", predictionCount?.multiplecountResult)
        let answerTmp = [...answer]
        let answer_sorted_str = answerTmp.sort().join(",");

        let prePredictedCount = getCountMultipleAnswerMatch(predictionCount?.multiplecountResult, answer_sorted_str)
        let tokenDecimals = prediction?.decimal
        let devidedBy = prePredictedCount + 1
        let paymentAfterDisturibution = prediction?.amount / devidedBy
        setPayoutRiskfreePrediction(parseFloat(formatBalanceWithDecimals(paymentAfterDisturibution, tokenDecimals)));
      }
    }

    if (prediction?.access_setting == 9) {
      if (answer?.length == 3) {
        console.log("answer selecteddddd all so calculate payout", answer);
        console.log("answer selecteddddd count", predictionCount?.multiplecountResult)
        let answer_sorted_str = answer.join(",");

        let prePredictedCount = getCountMultipleAnswerMatch(predictionCount?.multiplecountResult, answer_sorted_str)
        let tokenDecimals = prediction?.decimal
        let devidedBy = prePredictedCount + 1
        let paymentAfterDisturibution = prediction?.amount / devidedBy
        setPayoutRiskfreePrediction(parseFloat(formatBalanceWithDecimals(paymentAfterDisturibution, tokenDecimals)));
      }
    }

    if (prediction?.access_setting == 10) {
      if (answer?.length == 4) {
        console.log("answer selecteddddd all so calculate payout", answer);
        console.log("answer selecteddddd count", predictionCount?.multiplecountResult)
        let answer_sorted_str = answer.join(",");
        let prePredictedCount = getCountMultipleAnswerMatch(predictionCount?.multiplecountResult, answer_sorted_str)
        let tokenDecimals = prediction?.decimal
        let devidedBy = prePredictedCount + 1
        let paymentAfterDisturibution = prediction?.amount / devidedBy
        setPayoutRiskfreePrediction(parseFloat(formatBalanceWithDecimals(paymentAfterDisturibution, tokenDecimals)));
      }
    }



  };


  const handlePayoutRiskfreePredictionOld = () => {
    let predictedAnswerArray = [...answer]
    let isPrePredicted = []

    if (prediction?.access_setting == 8) {
      if (answer?.length == 3) {
        for (let j in allLeadBoardList) {
          let arr1 = allLeadBoardList[j].prediction
          let arr2 = predictedAnswerArray

          arr1.sort();
          arr2.sort();

          let sortedStr1 = arr1.join(",");
          let sortedStr2 = arr2.join(",");

          //Compare sorted strings
          if (sortedStr1 === sortedStr2) {
            isPrePredicted.push('true')
          }
        }
      }
      else {
        isPrePredicted = []
      }

      if (isPrePredicted.length > 0) {
        let tokenDecimals = prediction?.decimal
        let devidedBy = isPrePredicted.length + 1
        let paymentAfterDisturibution = prediction?.amount / devidedBy

        setPayoutRiskfreePrediction(parseFloat(formatBalanceWithDecimals(paymentAfterDisturibution, tokenDecimals)));
      }
    }
    else if (prediction?.access_setting == 9) {
      if (answer?.length == 3) {
        for (let j in allLeadBoardList) {
          let arr1 = allLeadBoardList[j].prediction
          let arr2 = predictedAnswerArray

          let stringValue1 = arr1.join(",");
          let stringValue2 = arr2.join(",");

          if (stringValue1 === stringValue2) {
            isPrePredicted.push('true')
          }
        }
      }
      else {
        isPrePredicted = []
      }
      if (isPrePredicted.length > 0) {
        let tokenDecimals = prediction?.decimal
        let devidedBy = isPrePredicted.length + 1
        let paymentAfterDisturibution = prediction?.amount / devidedBy
        setPayoutRiskfreePrediction(parseFloat(formatBalanceWithDecimals(paymentAfterDisturibution, tokenDecimals)));
      }
    }
    else if (prediction?.access_setting == 10) {
      if (answer?.length == 4) {
        for (let j in allLeadBoardList) {
          let arr1 = allLeadBoardList[j].prediction
          let arr2 = predictedAnswerArray

          let stringValue1 = arr1.join(",");
          let stringValue2 = arr2.join(",")

          if (stringValue1 === stringValue2) {
            isPrePredicted.push('true')
          }
        }
      }
      else {
        isPrePredicted = []
      }
      if (isPrePredicted.length > 0) {
        let tokenDecimals = prediction?.decimal
        let devidedBy = isPrePredicted.length + 1
        let paymentAfterDisturibution = prediction?.amount / devidedBy
        setPayoutRiskfreePrediction(parseFloat(formatBalanceWithDecimals(paymentAfterDisturibution, tokenDecimals)));
      }
    }
    else;
  };


  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: true },
  };


  const connectWallet = async () => {
    if (!iswallet) {
      let accounts = await Handleconnect();
      if (accounts == "meta_error") {
        handleClose();
      } else {
        if (accounts) {
          dispatch(setwalletConnected(true));
          dispatch(setWalletAddress(accounts));
          setis_walletConnected(true);
          try {
            const response = await apiService.predictionCheckforWallet(
              address.address,
              accounts[0]
            );
            if (response.status == 200) {
              prediction.canPredict = response.data.data.canPredict;
              console.log(response.data.data.canPredict);
              if (response.data.data.canPredict) {
                makeBid(accounts[0]);
              } else {
                swal(
                  "Error",
                  "Your wallet address is not on the whitelist for this prediction.",
                  "error"
                );
              }
            }
          } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
              swal({ text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
              });
            } else {
            }
          }
          handleClose();
          return true;
        } else {
          return true;
        }
      }
    }
  };

  const approveToken = async (
    toContract,
    tokenammount,
    price,
    predictionContract,
    walletAddress
  ) => {
    setbidbtn(true);
    settokenApproved(false);
    let transactionHash;
    try {
      let wallet = localStorage.getItem("connected_wallet");
      if (!wallet) {
        wallet = walletAddress;
      }

      console.log("====tokenammount====", tokenammount.toString());
      handleShowPlaceBetSteps();
      let sendParams = { from: wallet }, balacneInETH = 0;

      try {

        let web3 = new Web3(window.ethereum);
        let gasLimit;
        if (localStorage.getItem("user_type") == "CUSTODIAL") {

          web3 = new Web3(web3AuthProvider);
          const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
          const signer = ethersProvider.getSigner();
          const balance = await ethersProvider.getBalance(wallet);
          balacneInETH = ethers.utils.formatEther(balance);
          const gasLimit = await toContract.estimateGas.approve(prdictionUrlAddress, tokenammount.toString());
          //  gasLimit = await toContract.connect(signer).estimateGas.approve(address.address, tokenammount.toString(), sendParams)

        } else {
          gasLimit = await toContract.methods
            .approve(prdictionUrlAddress, tokenammount.toString())
            .estimateGas(sendParams)
            .then((res) => {
              return res;
            });
          sendParams.gasPrice = Math.ceil(Number(gasPriceGet) * gasFeePercent);
          sendParams.gas = Math.ceil(Number(gasLimit) * gasFeePercent);
        }
        console.log("======approvetoken gaslimit ======", gasLimit)
        let gasPriceGet = await web3.eth.getGasPrice();


      } catch (error) {
        // console.log("error approveToken get gas", error);
        console.log("error makePrediction calculating gas third ", error);
        if (error.code === 4001) {
          swal("Error", "User denied transaction signature.", "error");
        }
      }

      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        console.log("======localStorage.getItem user_type)====", toContract.methods)
        if (Number(balacneInETH) < 0.1) {
          await swal({
            content: {
              element: "div",
              attributes: {
                innerHTML: `
                  To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
                  <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
                  <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
                  <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
                `
              }
            },
            icon: "info",
          });
          
          setbidbtn(false);
          handleClosePlaceBetSteps();
          return false;
        }
        swal({
          text: "Are you sure you want to complete this transaction?",
          icon: "info",
          buttons: ["Cancel", "Approve tokens"]
        }).then(async (res) => {
          if (res) {
            const tx = await toContract.approve(prdictionUrlAddress, tokenammount.toString());
            console.log("Transaction Hash:", tx);
            const receipt = await tx.wait();
            if (receipt) {
              settokenApproved(true);
              console.log("==========Transaction successful, receipt:", receipt);
              console.log("settokenApproved to true");
              await makePrediction(predictionContract, price, walletAddress);
            }
          } else {
            setbidbtn(false);
            handleClosePlaceBetSteps();
            // swal("Error", "User denied transaction.", "error");
          }
        })
      } else {
        await toContract.methods.approve(address.address, tokenammount.toString())
          .send(sendParams)
          .on("transactionHash", function (hash) {
            transactionHash = hash;
          })
          .on("receipt", async function (tx) {
            console.log("=======receipt===");
            settokenApproved(true);
            console.log("settokenApproved to true");
            await makePrediction(predictionContract, price, walletAddress);
          })
          .on("confirmation", async function (conf) {
            //   console.log("=======confirmation===", conf);
          })
          .on("error", async function (error) {
            handleClosePlaceBetSteps();
            console.error("error  =====->", error.code);
            console.log("error makePrediction calculating gas first ", error);
            let err = error.toString();
            if (error.code == 4001) {
              swal("Error", "User denied transaction.", "error");
            } else if (err.includes("User denied transaction")) {
              swal("Error", "User denied transaction.", "error");
            } else if (err.includes("user rejected")) {
              swal("Error", "User denied transaction.", "error");
            }
            if (error.code == 432) {
              let validTransaction = checkTransaction(transactionHash);
              if (validTransaction) {
                await makePrediction(predictionContract, price, walletAddress);
              }
            }
          });
      }
    } catch (error) {
      console.log("error makePrediction calculating gas second ", error);
      console.error("error  =========->", error.toString());
      if (error.code == 432) {
        let validTransaction = checkTransaction(transactionHash);
        if (validTransaction) {
          await makePrediction(predictionContract, price, walletAddress);
        }
      } else {
        handleClosePlaceBetSteps();
        setbidbtn(false);
        console.log("error makePrediction calculating gas first ", error);
        let err = error.toString();
        if (error.code == 4001) {
          swal("Error", "User denied transaction.", "error");
        } else if (err.includes("User denied transaction")) {
          swal("Error", "User denied transaction.", "error");
        } else if (err.includes("user rejected")) {
          swal("Error", "User denied transaction.", "error");
        }
      }
    }
  };

  const checkTransaction = async (transactionHash) => {
    console.log("====transactionHash===", transactionHash);
    let web3 = new Web3(window.ethereum);
    if (localStorage.getItem("user_type") == "CUSTODIAL") {
      web3 = new Web3(web3AuthProvider);

    }
    web3.eth
      .getTransactionReceipt(transactionHash)
      .then((receipt) => {
        if (receipt) {
          console.log("====receipt===", receipt);
          if (receipt.status === true || receipt.status === "0x1") {
            return true;
          } else {
            return false;
          }
        } else {
          console.log(
            "Transaction receipt not found. It may still be pending."
          );
        }
      })
      .catch((error) => {
        console.error("Error getting transaction receipt:", error);
      });
  };


  const checkWalletConnect = async () => {
    let is_walletConnecte = localStorage.getItem(is_walletConnected);

    if (is_walletConnecte && prediction?.canPredict) {
      makeBid(localStorage.getItem("connected_wallet"));
    } else {
      handleShow();
    }
  };


  const getBalance = async () => {
    let webb3 = new Web3(window.ethereum);
    if (localStorage.getItem("user_type") == "CUSTODIAL") {
      webb3 = new Web3(web3AuthProvider);

    }
    let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);
    let userBalance = await toContract.methods.balanceOf(localStorage.getItem("connected_wallet").toString())
      .call();
    let balance = formatBalance(userBalance.toString());
    dispatch(setwalletBalance(balance));
  };


  const makeBid = async (walletAddress) => {
    console.log("makeBid");
    let checkconnection = await checkConnection();
    if (checkconnection) {
      let data = await checkprediction(address.address, walletAddress);
      if (data !== null) {
        swal("Error", "You have already bet on this prediction", "error");
        setmadebid(true);
        setbidbtn(true);
        return;
      }
      let answercount = await get_count_according_to_access_setting()
      if (answer && answer?.length < answercount) {

        swal({ icon: "error", text: "You need to select answer first.", button: "OK" }).then(() => {

          window.location.reload();
        });
        return;
      }
      try {
        let webb3 = new Web3(window.ethereum);

        let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);

        let CurrentchainId = await formatChainAsNum();
        if (CurrentchainId != chainId) {
          await switchNetwork();
        }


        if (prediction?.prediction_type == "PAID") {
          let userBalance = await toContract.methods
            .balanceOf(walletAddress.toString())
            .call();
          let balance = formatBalance(userBalance.toString());
          if ((!price || parseInt(price) == 0) && walletAddress) {
            if (parseInt(price) == 0) {
              setpriceErr("Minium Bet Amount: 1 USDC");
            } else {
              setpriceErr("Enter valid price");
            }
            setbidbtn(false);
            return;
          }
          if (price) {
            console.log("tushar 1", comission);
            var commissionAmount = (comission / 100) * price;
            var totaltoken = parseFloat(price) + parseFloat(commissionAmount);
            if (parseFloat(balance) < parseFloat(totaltoken)) {
              // await swal({
              //   content: {
              //     element: "div",
              //     attributes: {
              //       innerHTML: `
              //         To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
              //         <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
              //         <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
              //         <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
              //       `
              //     }
              //   },
              //   icon: "info",
              // });
              await FundAddModal();
              setbidbtn(false);
              handleClosePlaceBetSteps();
              return;
            }

            let predictionContract;
            if (prediction?.is_old_prediction) {
              predictionContract = new webb3.eth.Contract(contractOldAbi, prdictionUrlAddress);
            }
            else {
              contractAbi = Number(prediction?.platformFee) && Number(prediction?.platformFee) > 0 ? contractAbiPlatformFee : PredictionAbi;
              predictionContract = new webb3.eth.Contract(contractAbi, prdictionUrlAddress);

            }
            let tokenammount = ethers.utils.parseUnits(
              totaltoken.toString(),
              decimalTo
            );
            let tokenprice = ethers.utils.parseUnits(
              price.toString(),
              decimalTo
            );
            if (localStorage.getItem("user_type") == "CUSTODIAL") {
              webb3 = new Web3(web3AuthProvider);
              const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
              const signer = ethersProvider.getSigner();
              contractAbi = Number(prediction?.platformFee) && Number(prediction?.platformFee) > 0 ? contractAbiPlatformFee : PredictionAbi;
              predictionContract = new ethers.Contract(
                prdictionUrlAddress,
                contractAbi,
                signer
              );
              toContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
            }


            console.log("======toContract=====", toContract)
            await approveToken(
              toContract,
              tokenammount,
              tokenprice,
              predictionContract,
              walletAddress
            );
          }
        } else if (prediction?.prediction_type == "RISK-FREE" || prediction.isConsolidated) {
          let predictionContract;
          if (prediction?.is_old_prediction) {
            predictionContract = new webb3.eth.Contract(
              contractOldAbi,
              prdictionUrlAddress
            );
          }
          else {
            contractAbi = (prediction && prediction.isConsolidated) ? ConsolidatedPredictionAbi : (Number(prediction?.platformFee) && Number(prediction?.platformFee) > 0 ? contractAbiPlatformFee : PredictionAbi);
            predictionContract = new webb3.eth.Contract(
              contractAbi,
              prdictionUrlAddress
            );

          }
          console.log("predictionContract-------->", predictionContract);


          if (localStorage.getItem("user_type") == "CUSTODIAL") {
            webb3 = new Web3(web3AuthProvider);
            const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
            const signer = ethersProvider.getSigner();
            contractAbi = (prediction && prediction.isConsolidated) ? ConsolidatedPredictionAbi : (Number(prediction?.platformFee) && Number(prediction?.platformFee) > 0 ? contractAbiPlatformFee : PredictionAbi);
            predictionContract = new ethers.Contract(
              prdictionUrlAddress,
              contractAbi,
              signer
            );

          }
          // here to show modal
          handleShowPlaceBetRiskFreeSteps();
          await makePrediction(predictionContract, 0, walletAddress);
        }
      } catch (error) {
        console.log(error, "=====error");
      }
    }
  };


  const getpercentage = (truevalue, falsevalue) => {
    console.log("getpercentage>>>>", truevalue, falsevalue)
    let totalval = truevalue + falsevalue;
    let truepercentage = (truevalue / totalval) * 100;
    console.log("getpercentage truepercentage=======", truepercentage);
    console.log("getpercentage ===truepercentage===", truepercentage);
    let falsepercentage = (falsevalue / totalval) * 100;
    settruePer(truepercentage);
    setfalsPer(falsepercentage);
  };


  const getpercentageriskFree = (truevalue, falsevalue) => {
    let totalCount = truevalue + falsevalue;
    let riskfreepayout = (truevalue * 100) / totalCount;
    settruePer(parseFloat(riskfreepayout));
    // let totalFalseCount = truevalue ;
    // let riskfreepayoutFalse = amount / totalFalseCount;
    // setfalsPer(parseFloat(formatBalance(riskfreepayoutFalse)))
    // let falsepercentage = falsevalue;
    // console.log("truepercentage, falsepercentage, amount", riskfreepayout, falsepercentage)
  };




  async function calculateDiscount(userWalletAddress) {
    try {
      let web3 = new Web3(window.ethereum);
      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        web3 = new Web3(web3AuthProvider);
      }

      const discountContractInstance = new web3.eth.Contract(discountAbi, discountContract);

      // Validate the user wallet address
      if (!web3.utils.isAddress(userWalletAddress)) {
        throw new Error("Invalid wallet address");
      }

      // Call the `getDiscount` function from the smart contract
      const discount = await discountContractInstance.methods
        .getDiscount(userWalletAddress)
        .call();

      console.log(`Discount for ${userWalletAddress}:`, discount);
      return parseInt(discount); // Return the discount value
    } catch (error) {
      console.error("Error calculating discount:", error.message);
      throw error;
    }
  }

  async function calculatePlatformFeeReduced(userWalletAddress, platformFee) {
    try {
      // Fetch the discount for the user
      const discount = await calculateDiscount(userWalletAddress);

      // Validate and calculate the reduced platform fee dynamically
      if (discount < 0 || discount > 100) {
        throw new Error("Invalid discount percentage received");
      }

      const reducedPlatformFee = platformFee * (1 - discount / 100);

      console.log(`Original Fee: ${platformFee}, Reduced Fee: ${reducedPlatformFee}`);
      return reducedPlatformFee; // Return the adjusted platform fee
    } catch (error) {
      console.error("Error in calculatePlatformFeeReduced:", error.message);
      throw error;
    }
  }


  const makePrediction = async (
    predictionContract,
    amt,
    walletAddressconnected
  ) => {
    try {
      console.log("make prediction called", predictionContract);

      let walletAddress = localStorage.getItem("connected_wallet");
      if (!walletAddress) {
        walletAddress = walletAddressconnected;
      }

      let answerCreated;
      if (prediction?.is_old_prediction) {
        answerCreated = (answer === "true" || answer === "True") ? true : false;
      } else {
        answerCreated = answer.toString()
      }

      let predictionBetsCount = await apiService.getPredictionBetsCount(address.address);
      predictionBetsCount = predictionBetsCount?.data?.data?.totalBet ? predictionBetsCount?.data?.data?.totalBet : 0;
      if (prediction && prediction.isConsolidated && predictionBetsCount >= 5) {
        setbidbtn(false);
        setmadebid(false);
        get_predictionDetal(address);
        getBalance();
        handleClosePlaceBetRiskFreeSteps();
        handleClosePlaceBetSteps();
        return;
      }
      
      let platformFee = Number(prediction?.platformFee); // Extract numerical value for platformFee
      console.log("arayna 1", platformFee);
      if (prediction?.data_provider === "solo-predictions" && predictionBetsCount > 0) {
        platformFee = Number(prediction.betExtraplatformFee);
      }


      console.log("arayna 2", platformFee);
      
      // here need to calculate discount percentage
      let platformFeeReduced =platformFee;
      if (prediction && prediction?.is_discount_applicable) {
        platformFeeReduced = await calculatePlatformFeeReduced(walletAddress, platformFee);
      }
      console.log("arayna 3", platformFeeReduced);
      let sendParams = { from: walletAddress, value: platformFeeReduced ? Number(platformFeeReduced).toString() : 0 };
      console.log("sendParamswith wallet");
      let web3 = new Web3(window.ethereum);
      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        web3 = new Web3(web3AuthProvider);

      }
      let gasPriceGet = await web3.eth.getGasPrice();
      try {
        let gasLimit;
        if (prediction && prediction.isConsolidated && localStorage.getItem("user_type") != "CUSTODIAL") {
          gasLimit = await predictionContract.methods
            .betRiskfree(questionUrl, answerCreated)
            .estimateGas(sendParams)
            .then((res) => {
              return res;
            });
          sendParams.gasPrice = Math.ceil(Number(gasPriceGet) * gasFeePercent);
          sendParams.gas = Math.ceil(Number(gasLimit) * gasFeePercent);
        } else if (prediction?.prediction_type == "RISK-FREE") {
          if (localStorage.getItem("user_type") == "CUSTODIAL") {

            console.log("===predictionContract====CUSTODIAL==", predictionContract)
            //  gasLimit = await predictionContract.estimateGas.betRiskfree(answerCreated, amt.toString(),sendParams);

            console.log("=====gasLimit====", gasLimit)
          } else {
            gasLimit = await predictionContract.methods
              .betRiskfree(answerCreated, amt.toString())
              .estimateGas(sendParams)
              .then((res) => {
                return res;
              });
            sendParams.gasPrice = Math.ceil(Number(gasPriceGet) * gasFeePercent);
            sendParams.gas = Math.ceil(Number(gasLimit) * gasFeePercent);

          }
        } else {

          if (localStorage.getItem("user_type") == "CUSTODIAL") {
            // gasLimit = 100000;
            //    gasLimit = await predictionContract.estimateGas.bet(answerCreated, amt.toString(),sendParams);
            //  console.log("=====gasLimit====",gasLimit)
          } else {
            gasLimit = await predictionContract.methods
              .bet(answerCreated, amt.toString())
              .estimateGas(sendParams)
              .then((res) => {
                return res;
              });
            sendParams.gasPrice = Math.ceil(Number(gasPriceGet) * gasFeePercent);
            sendParams.gas = Math.ceil(Number(gasLimit) * gasFeePercent);
          }

        }

      } catch (error) {
        settokenApproved(false);
        handleClosePlaceBetSteps();
        console.log("error makePrediction calculating gas ", error);
        if (error.code === 4001) {
          swal("Error", "User denied transaction signature.", "error");
        }
      }

      console.log("====sendParams===", sendParams);
      let betCall;

      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
        const balance = await ethersProvider.getBalance(walletAddress);
        const balacneInETH = ethers.utils.formatEther(balance);
        const totalGasFee = Number(gasPriceGet) * 500000;
        console.log("totalGasFee-wei->", totalGasFee);
        console.log("balance---->", balance);


        if (Number(balance) < ((Number(sendParams.value)) + (totalGasFee))) {
          // await swal({
          //   text: "Insufficient POL balance.",
          //   icon: "info",
          //   buttons: ["Close", "Add Funds"]
          // }).then(res => {
          //   if (res) {
          //     showCheckout();
          //   }
          // });
          // await swal({
          //   content: {
          //     element: "div",
          //     attributes: {
          //       innerHTML: `
          //         To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
          //         <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
          //         <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
          //         <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
          //       `
          //     }
          //   },
          //   icon: "info",
          // });
          await FundAddModal();
          setbidbtn(false);
          setmadebid(false);
          get_predictionDetal(address);
          getBalance();
          handleClosePlaceBetRiskFreeSteps();
          handleClosePlaceBetSteps();
          return false;
        }
        swal({
          text: "Are you sure you want to complete this transaction?",
          icon: "info",
          buttons: ["Cancel", "Confirm"]
        }).then(async (res) => {
          if (res) {
            if (prediction && prediction.isConsolidated) {
              betCall = await predictionContract.betRiskfree(questionUrl, answerCreated, {
                gasLimit: 500000, // Fallback if gas estimation fails,
                value: sendParams.value
              });
            } else if (prediction?.prediction_type == "RISK-FREE") {
              betCall = await predictionContract.betRiskfree(answerCreated, {
                gasLimit: 500000, // Fallback if gas estimation fails,
                value: sendParams.value
              });
            } else {
              betCall = await predictionContract.bet(answerCreated, amt.toString(), {
                gasLimit: 500000, // Fallback if gas estimation fails
                value: sendParams.value
              });
            }
            const receipt = await betCall.wait();

            if (receipt) {

              console.log("receipt Transaction Hash:", receipt);
              checkBet(receipt.blockNumber, address.address);
              swal("Success", "Prediction placed successfully.", "success");
              setmadebid(true);
              setbidbtn(true);

              setTimeout(() => {
                get_predictionDetal(address);
                leaderboardDetail(address, limit);
              }, 1000);


              // setTimeout(() => {
              //   console.log("getting the interval");
              //   getAllLeadboardList(address,limit)
              // }, 2000);

            } else {
              await swal("Error", "Something went wrong.", "error").then(res => {
                window.location.reload();
              });
            }

          } else {
            setbidbtn(false);
            setmadebid(false);
            get_predictionDetal(address);
            getBalance();
            // swal("Error", "User denied transaction.", "error");
          }
          handleClosePlaceBetRiskFreeSteps();
          handleClosePlaceBetSteps();
        })

      } else {
        if (prediction && prediction.isConsolidated) {
          betCall = await predictionContract._methods.betRiskfree(questionUrl, answerCreated)
        } else if (prediction?.prediction_type == "RISK-FREE") {
          betCall = await predictionContract.methods.betRiskfree(answerCreated);
        } else {
          betCall = await predictionContract.methods.bet(answerCreated, amt.toString());
        }
        await betCall
          .send(sendParams)
          .on("receipt", function (tx) {
            checkBet(tx.blockNumber, address.address);
            setbidbtn(false);
            handleClosePlaceBetSteps();
            swal("Success", "Prediction placed successfully.", "success");
            setmadebid(true);
            setbidbtn(true);
            get_predictionDetal(address);
            handleClosePlaceBetRiskFreeSteps();
            getBalance();

            setTimeout(() => {
              get_predictionDetal(address);
            }, 1000);

          })
          .on("error", async function (error) {
            handleClosePlaceBetRiskFreeSteps();
            setbidbtn(false);
            setmadebid(false);
            setbidbtn(false);
            let err = error.toString();
            if (error.code == 4001) {
              swal("Error", "User denied transaction.", "error");
            } else if (err.includes("User denied transaction")) {
              swal("Error", "User denied transaction.", "error");
            } else if (err.includes("user rejected")) {
              swal("Error", "User denied transaction.", "error");
            } else {
              await swal("Error", "Something went wrong.", "error").then(res => {
                window.location.reload();
              });
            }
            settokenApproved(false);
            handleClosePlaceBetSteps();
          });
      }
    } catch (error) {
      console.log(" handleClosePlaceBetSteps()");
      console.log("error============>", error);
      settokenApproved(false);
      let err = error.toString();
      if (error.code == 4001) {
        swal("Error", "User denied transaction.", "error");
      } else if (err.includes("User denied transaction")) {
        swal("Error", "User denied transaction.", "error");
      } else if (err.includes("user rejected")) {
        swal("Error", "User denied transaction.", "error");
      }
      handleClosePlaceBetSteps();
      handleClosePlaceBetRiskFreeSteps();
      setbidbtn(false);
      setmadebid(false);
      setbidbtn(false);
    }
  };


  async function checkBet(blockNumber, address) {
    try {
      blockNumber = blockNumber.toString();
      let data = {
        toBlock: blockNumber,
        fromBlock: blockNumber,
        contract_address: address,
        is_old_prediction: prediction?.is_old_prediction
      };
      const response = await apiService.checkBet(data);
      if (response.status == 200) {
        setleaderboard(response.data.data);
      }
    } catch (error) {
      console.log("====error===", error);
      //   setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }

  async function switchNetwork() {
    try {
      let chain = ethers.utils.hexValue(chainId);
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }],
      });
      return true;
    } catch (switchError) {
      if (switchError.code === 4902) {
        swal("error", "Please add the Polygon network to MetaMask", "error");
      }
      return false;
      console.log("Cannot switch to the network");
    }
  }


  function viewMore(lmt) {
    setlimit(lmt);
    leaderboardDetail(address.address, lmt);
  }


  function back() {
    navigate(-1)
    // window.location.href = "/";
  }


  const tooltipbonus = (
    <Tooltip id="tooltip">
      Each winner receives a code redeemable for mystery boxes containing EARNM tokens and other valuable prizes. Visit the transactions page to view your codes
    </Tooltip>
  );


  const [showNft, setShowNft] = useState(false);

  const handleCloseNft = () => setShowNft(false);
  const handleShowNft = () => setShowNft(true);

  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Holy guacamole!</strong> Check this info.
    </Tooltip>
  );

  function mintNFTByMintId(index) {
    setMintNftIndex(index);
    setTimeout(() => {
      setShowNft(false);
    }, 200);
  }


  const [showFollowSteps, setShowFollowSteps] = useState(false);
  const [tokenApprovedForNFT, setTokenApprovedForNFT] = useState(false);
  useEffect(() => {
    async function init() {
      if (mintNftIndex === 0 || 0 < mintNftIndex) {
        const mintData = mintNftsList.find(item => item.mintId === mintNftIndex);
        console.log("ajit", mintData);

        await mintNft(mintData);
        setMintNftIndex(null);
        console.log("ajit null");

      }
    }
    init();
  }, [mintNftIndex]);

  async function approveTokenForMintNFT(mintDate) {
    try {
      const web3 = new Web3(web3AuthProvider);
      const tokenAddressContractAddress = (mintDate.mintId == 9 || mintDate.mintId == 10) ? folioContractAddress : tokenAddress
      let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddressContractAddress);
      let eRC20TokenBalance = await tokenContract.methods.balanceOf(walletAddress).call();
      const decimals = await tokenContract.methods.decimals().call();
      eRC20TokenBalance = Number(eRC20TokenBalance) / 10 ** Number(decimals);
      let tokenAmount = Number(mintDate.tokenValue > 100 ? mintDate.tokenValue : 100) * 10 ** Number(decimals);
       tokenAmount = tokenAmount.toLocaleString('fullwide', { useGrouping: false });

      console.log("eRC20TokenBalance <= mintDate.tokenValue", eRC20TokenBalance, mintDate.tokenValue);

      if (eRC20TokenBalance <= mintDate.tokenValue) {
        if (localStorage.getItem("user_type") == "CUSTODIAL") {
          await swal({
            content: {
              element: "div",
              attributes: {
                innerHTML: `
                  To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
                  <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
                  <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
                  <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
                `
              }
            },
            icon: "info",
          });
        } else {
          await swal({
            content: {
              element: "div",
              attributes: {
                innerHTML: `
                  To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
                  <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
                  <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
                  <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
                `
              }
            },
            icon: "info",
          });
        }
        return false;
      } else {
        let allowanceInWei = await tokenContract.methods.allowance(walletAddress, NftBuyContractAddress).call();
        allowanceInWei = Number(allowanceInWei) / 10 ** Number(decimals)
        if (allowanceInWei <= mintDate.tokenValue) {
          if (localStorage.getItem("user_type") == "CUSTODIAL") {
            const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
            const signer = ethersProvider.getSigner();
            tokenContract = new ethers.Contract(tokenAddressContractAddress, tokenAbi, signer);
            const balance = await ethersProvider.getBalance(walletAddress);
            let balacneInETH = ethers.utils.formatEther(balance);
            if (Number(balacneInETH) < 0.1) {
              // await swal({
              //   text: "Insufficient POL balance.",
              //   icon: "info",
              //   buttons: ["Close", "Add Funds"]
              // }).then(res => {
              //   if (res) {
              //     showCheckout();
              //   }
              // });
              // await swal({
              //   content: {
              //     element: "div",
              //     attributes: {
              //       innerHTML: `
              //         To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
              //         <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
              //         <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
              //         <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
              //       `
              //     }
              //   },
              //   icon: "info",
              // });
              await FundAddModal();
              return false;
            } else {
              return await swal({
                text: "Are you sure you want to complete this transaction?",
                icon: "info",
                buttons: ["Cancel", "Approve tokens"]
              }).then(async (res) => {
                if (res) {
                  const tx = await tokenContract.approve(NftBuyContractAddress, tokenAmount);
                  const receipt = await tx.wait();
                  if (receipt) {
                    console.log("==========Transaction successful, receipt:", receipt);
                    return true;
                  } else {
                    await swal("Error", "Something went wrong.", "error").then(res => {
                      window.location.reload();
                    });
                  }
                } else {
                  console.log("Cancel  ajit --------->Cancel");

                  setShowFollowSteps(false);
                  return false;
                }
              })
            }
          } else {

            let gas, gasPrice;
            try {
              gasPrice = await web3.eth.getGasPrice();
              gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);
              gas = await tokenContract.methods.approve(NftBuyContractAddress, tokenAmount).estimateGas({ from: walletAddress });
              gas = Math.ceil(Number(gas) * gasFeePercent);
            } catch (error) {

            }

            const receipt = await tokenContract.methods.approve(NftBuyContractAddress, tokenAmount).send({
              from: walletAddress,
              gas: gas,  // Dynamically estimated gas
              gasPrice: gasPrice  // Current gas price
            });
            console.log("==========Transaction successful, receipt:", receipt);
            allowanceInWei = await tokenContract.methods.allowance(walletAddress, NftBuyContractAddress).call();
            allowanceInWei = Number(allowanceInWei) / 10 ** Number(decimals)
            if (allowanceInWei <= mintDate.tokenValue) {
              return approveTokenForMintNFT(mintDate);
            }
            return true
          }
        } else {
          return true;
        }

      }
    } catch (error) {
      console.log("error ajit", error);
      return false;
    }
  }

  async function mintNft(mintData) {
    try {
      setShowFollowSteps(true);
      console.log("mintData", mintData);
      // return

      const isApproved = await approveTokenForMintNFT(mintData);
      console.log("isApproved ajit", isApproved);

      if (isApproved) {
        setShowFollowSteps(true);
        setTokenApprovedForNFT(true);
        const web3 = new Web3(web3AuthProvider);
        let mintContract = new web3.eth.Contract(NftBuyAbi, NftBuyContractAddress);
        if (localStorage.getItem("user_type") == "CUSTODIAL") {
          const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
          const signer = ethersProvider.getSigner();
          mintContract = new ethers.Contract(NftBuyContractAddress, NftBuyAbi, signer);
          const balance = await ethersProvider.getBalance(walletAddress);
          let balacneInETH = ethers.utils.formatEther(balance);
          if (Number(balacneInETH) < 0.1) {
            // await swal({
            //   text: "Insufficient POL balance.",
            //   icon: "info",
            //   buttons: ["Close", "Add Funds"]
            // }).then(res => {
            //   if (res) {
            //     showCheckout();
            //   }
            // });
            // await swal({
            //   content: {
            //     element: "div",
            //     attributes: {
            //       innerHTML: `
            //         To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
            //         <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
            //         <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
            //         <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
            //       `
            //     }
            //   },
            //   icon: "info",
            // });
            await FundAddModal();
            setShowFollowSteps(false);
          } else {
            await swal({
              text: "Are you sure you want to complete this transaction?",
              icon: "info",
              buttons: ["Cancel", "Confirm"]
            }).then(async (res) => {
              const tx = await mintContract.mintNFT(mintData.mintId, {
                gasLimit: 500000, // Fallback if gas estimation fails
              });
              if (res) {
                const receipt = await tx.wait();
                if (receipt) {
                  console.log("==========Transaction successful, receipt:", receipt);
                  swal("Success", "NFT minted successfully.", "success").then(res => {
                    window.location.reload();
                  });
                  setShowFollowSteps(false);
                  setTokenApprovedForNFT(false);
                } else {
                  swal("Error", "Something went wrong.", "error").then(res => {
                    window.location.reload();
                  });
                }
              } else {
                setShowFollowSteps(false);
              }
            })
          }
        } else {
          let gas, gasPrice;
          try {
            gasPrice = await web3.eth.getGasPrice();
            gasPrice = Math.ceil(Number(gasPrice) * gasFeePercent);
            gas = await mintContract.methods.mintNFT(mintData.mintId).estimateGas({ from: walletAddress });
            gas = Math.ceil(Number(gas) * gasFeePercent);
          } catch (error) {

          }

          const receipt = await mintContract.methods.mintNFT(mintData.mintId).send({
            from: walletAddress,
            gas: gas,  // Dynamically estimated gas
            gasPrice: gasPrice  // Current network gas price
          });
          console.log("==========Transaction successful, receipt:", receipt);
          swal("Success", "NFT minted successfully.", "success").then(res => {
            window.location.reload();
          });
          setShowFollowSteps(false);
          setTokenApprovedForNFT(false);
        }
      } else {
        console.log("setShowFollowSteps ajit", showFollowSteps);

        setShowFollowSteps(false);
      }

    } catch (error) {
      console.log("error ajit", error);
      setShowFollowSteps(false);
      setTokenApprovedForNFT(false);
      swal("Error", "Something went wrong.", "error").then(res => {
        window.location.reload();
      });
    }
  }

  const adData = adConfig["PredictionDetail"]; // Get specific page ad config

  console.log("adData:::::::", adData);


  return (
    <>
      <div className="ads-banner-top">
        {window.innerWidth <= 768 ? (
          <AdComponent adId={adData.ids[4]} dimension={adData.dimensions[4]} className="follow-steps-ad-mid" />
        ) : (
          <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className="contraffic_home_add" />
        )}
      </div>
      <section className="common-padding prediction-detail-portion">
        {loader && (
          <>
            {" "}
            <ThreeDotSpinner />
          </>
        )}
        <NotConnectWalletPopup
          show={show}
          handleClose={handleClose}
          connectWallet={connectWallet}
        />
        <Container className="spacing-area-section">
          <div className="detail-top-area">
            <div className="back-top-btn">
              <Button type="button" onClick={back} variant="unset">
                <i class="fa fa-angle-left" aria-hidden="true"></i> Back
              </Button>
            </div>
            {prediction?.result != null && prediction?.resultImage ? (
              <div className="back-top-btn">
                <a
                  href={
                    isiPhoneWithSafari
                      ? prediction.resultImage.replace(
                        "gateway.pinata.cloud",
                        "ipfs.io"
                      )
                      : prediction.resultImage
                  }
                  target="_blank"
                >
                  View Result{" "}
                </a>
              </div>
            ) : null}
          </div>

          <Row>
            <Col md={12} lg={8} xl={8}>
              <div className="prediction-detail-left">
                <div className="prediction-detail-image">
                  {prediction?.image != null &&
                    prediction?.image !== "undefined" &&
                    prediction?.image !== "" ? (
                    <img
                      src={
                        prediction?.image.includes("lh3.googleusercontent.com")
                          ? prediction?.image
                          : baseUrl + prediction?.image
                      }
                      alt="predict"
                    />
                  ) : (
                    <img src={require("../assets/images/dummy.png")} alt="img" />
                  )}
                  <AdComponent adId={adData.ids[1]} dimension={adData.dimensions[1]} className={"follow-steps-ad-mid"} />
                </div>


                <div className="prediction-detail-left-content">
                  <h5>{prediction?.question}</h5>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: prediction?.description,
                    }}
                    onClick={(event) => {
                      if (
                        event.target.tagName === "A" &&
                        !event.target.target
                      ) {
                        event.preventDefault();
                        window.open(event.target.href, "_blank");
                      }
                    }}
                  />
                  <div className="left-spacing-area">
                    {prediction?.prediction_category == "QUIZ" ?
                      <h6>Select Your Answer:</h6>
                      :
                      <h6>Make Your Prediction:</h6>
                    }
                    <Form>
                      {prediction?.prediction_type !== "RISK-FREE" && (
                        <div className="bet-amount-area">
                          <Form.Group
                            className=" mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Bet Amount(USDC):</Form.Label>
                            <Form.Control
                              type="text"
                              value={price}
                              className={madebid && "disable"}
                              maxLength={10}
                              placeholder="Enter in USDC"
                              disabled={madebid ? true : false}
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value?.match(/[0-9]/g)) {
                                  pricePrediction(
                                    value?.match(/[0-9]/g).join("")
                                  );
                                } else {
                                  pricePrediction("");
                                }
                              }}
                            />
                            <span className="error">{priceErr}</span>
                            {price && price > 0 && (
                              <div className="comm-text-area">
                                <p>+{platformFee}</p>
                                <p>Comm: {comission}%</p>
                              </div>
                            )}
                          </Form.Group>
                        </div>
                      )}



                      <div className="selected-section-outer">
                        <Form.Group className="prediction-detail-option-section">
                          {prediction?.prediction_category == "QUIZ" ?
                            <Form.Label>Answers:</Form.Label>
                            :
                            <Form.Label>Prediction:</Form.Label>

                          }
                          <div className="prediction-content-outer">
                            {prediction?.answer_option?.map((option) => (
                              <div
                                key={`detail-prediction-option-${option}`}
                                className="mb-2 radio-btn-option prediction-detail-options"
                                onClick={(e) => anserpredict(option)}
                              >
                                <Form.Check
                                  className={
                                    answer && answer.map(item => typeof item === "string" ? item.trim() : item).includes(option)
                                      ? "active"
                                      : ""
                                  }
                                  type="radio"
                                  id={`radio-${option}`}
                                  label={option.toString()}
                                  name="outcome"
                                  checked={answer && answer.map(item => typeof item === "string" ? item.trim() : item).includes(option)
                                    ? true
                                    : false
                                  }
                                  disabled={madebid ? true : false}
                                  onClick={(e) =>
                                    anserpredict(option)
                                  }
                                />

                              </div>
                            ))}
                          </div>
                        </Form.Group>

                        {prediction?.userOutcome == false && (new Date(prediction?.endDateTime) > new Date()) && (prediction?.access_setting == 8 || prediction?.access_setting == 9 || prediction?.access_setting == 10) ?
                          <div className="selected-option-area">
                            <Form.Label>Selected Options:</Form.Label>
                            {console.log("========answer=====", answer)}
                            {
                              (answer.length == 0) && (prediction?.access_setting == 10) ?
                                [...Array(4)].map((_, index) => (
                                  <ul>
                                    <li><span>{index + 1}</span><p>{"..."}</p></li>
                                  </ul>
                                ))
                                :
                                answer.length == 0 ?
                                  [...Array(3)].map((_, index) => (
                                    <ul>
                                      <li><span>{index + 1}</span><p>{"..."}</p></li>
                                    </ul>
                                  ))
                                  :
                                  null
                            }

                            {
                              (prediction?.access_setting == 10) && (answer && answer.length > 0) ?
                                answer.map((ans, index) => (
                                  <ul>
                                    <li><span>{index + 1}</span><p>{ans}</p></li>
                                    {
                                      (answer && answer.length == 1) && (index == 0) ?
                                        <>
                                          <li><span>2</span><p>{"..."}</p></li>
                                          <li><span>3</span><p>{"..."}</p></li>
                                          <li><span>4</span><p>{"..."}</p></li>
                                        </>
                                        :
                                        (answer && answer.length == 2) && (index == 1) ?
                                          <>
                                            <li><span>3</span><p>{"..."}</p></li>
                                            <li><span>4</span><p>{"..."}</p></li>
                                          </>
                                          :
                                          (answer && answer.length == 3) && (index == 2) ?
                                            <>
                                              <li><span>4</span><p>{"..."}</p></li>
                                            </>
                                            :
                                            null
                                    }
                                  </ul>
                                ))
                                :
                                (answer && answer.length > 0) ?
                                  answer.map((ans, index) => (
                                    <ul>
                                      <li><span>{index + 1}</span><p>{ans}</p></li>
                                      {
                                        (answer && answer.length == 1) && (index == 0) ?
                                          <>
                                            <li><span>2</span><p>{"..."}</p></li>
                                            <li><span>3</span><p>{"..."}</p></li>
                                          </>
                                          :
                                          (answer && answer.length == 2) && (index == 1) ?
                                            <>
                                              <li><span>3</span><p>{"..."}</p></li>
                                            </>
                                            :
                                            null
                                      }
                                    </ul>
                                  ))
                                  :
                                  null
                            }
                          </div>
                          :
                          null
                        }

                        {prediction?.userOutcome && (prediction?.access_setting == 8 || prediction?.access_setting == 9 || prediction?.access_setting == 10) ?
                          <div className="selected-option-area">
                            {

                              prediction?.userOutcome.split(',').map((ans, index) => (
                                <ul><li><span>{index + 1}</span><p>{ans}</p></li></ul>
                              ))


                            }
                          </div>
                          :
                          null


                        }


                      </div>

                    </Form>
                    {price && price > 0 && prediction?.result == null && (
                      <div className="predict-payout-area">
                        <p>
                          Predict Payout: <span>{payoutprediction} USDC</span>
                        </p>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                          {prediction?.prediction_type == "RISK-FREE" ?
                            "This payout is subject to change and calculated by dividing the prize pool by the number of participants who placed a bet."
                            :
                            "This payout is subject to change and calculated by considering the bets made by other participants and the current total value locked (TVL) so far."
                          }

                        </Tooltip>}>
                          <Button bsstyle="default">
                            {" "}
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}

                    {prediction &&
                      prediction?.prediction_type == "RISK-FREE" &&
                      prediction?.result == null && (
                        <>
                          <p className="prize-pool">
                            Prize Pool:{" "}
                            <span>
                              {formatBalanceWithDecimals(
                                prediction?.amount,
                                prediction?.decimal
                              )}{" "}
                              {prediction?.symbol}
                            </span>
                          </p>
                          {/* <p className="bonus-prize">
                            <OverlayTrigger placement="top" overlay={tooltipbonus}>
                              <Button bsStyle="default">Bonus: <span>1 Mystery Box Code</span></Button>
                            </OverlayTrigger>
                          </p> */}
                          <div className="predict-payout-area">
                            <p>
                              Predict Payout:{" "}
                              <span>
                                {payoutRiskfreeprediction} {prediction?.symbol}
                              </span>
                            </p>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                              {prediction?.prediction_type == "RISK-FREE" ?
                                "This payout is subject to change and calculated by dividing the prize pool by the number of participants who placed a bet."
                                :
                                "This payout is subject to change and calculated by considering the bets made by other participants and the current total value locked (TVL) so far."
                              }

                            </Tooltip>}>
                              <Button bsstyle="default">
                                {" "}
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </>
                      )}
                    {console.log("placebet conditions",
                      "isBetOnPrediction", (prediction?.isBetOnPrediction),
                      "madebid", (madebid),
                      "bidbtn", (bidbtn),
                      "canNotPredict", (!prediction?.canPredict ? true : false),
                      "isTimeRemaningForBet", (new Date(betEndTime?.format()) > new Date() ? false : (!isTimeRemaningForBet)),
                      "isExtendedNft", (new Date(betEndTime?.format()) > new Date() ? false : (!isExtendedNft)),
                      "canBetOnRiskFree", (prediction?.prediction_type == "RISK-FREE" && !prediction?.canBetOnRiskFree)
                    )}
                    <Button
                      type="button"
                      disabled={
                        (prediction && predictionCount && prediction?.isConsolidated && predictionCount?.totalCount >= 5) ||
                        (prediction?.isBetOnPrediction) ||
                        (madebid) ||
                        (bidbtn) ||
                        (!prediction?.canPredict ? true : false) ||
                        (new Date(betEndTime?.format()) > new Date() ? false : (!isTimeRemaningForBet)) ||
                        (new Date(betEndTime?.format()) > new Date() ? false : (!isExtendedNft)) ||
                        (prediction?.prediction_type == "RISK-FREE" && !prediction?.canBetOnRiskFree)
                      }
                      variant="unset"
                      onClick={(e) =>
                        iswallet || localStorage.getItem("is_walletConnected")
                          ? (makeBid(localStorage.getItem("connected_wallet")),
                            setbidbtn(true))
                          : checkWalletConnect()
                      }
                    >
                      {prediction?.prediction_category == "QUIZ" ?
                        "Submit"
                        :
                        "Place Bet"
                      }
                    </Button>

                    {extendedNftRemaning == 0 && (prediction?.data_provider == "solo-predictions" || prediction?.data_provider == "multi-choice-predictions") ?
                      <Button variant="primary" onClick={handleShowNft} className="buy-more-time">Buy More Time</Button> :
                      extendedNftRemaning > 0 && (prediction?.data_provider == "solo-predictions" || prediction?.data_provider == "multi-choice-predictions") &&
                      <Button variant="primary" className="buy-more-time" disabled={true}>
                        Extra Time Added
                      </Button>
                    }
                    {(prediction?.result) ? <NftMint prediction={prediction} showNftMint={false} walletAddress={walletAddress} mintNft={mintNft} showFollowSteps={showFollowSteps} tokenApprovedForNFT={tokenApprovedForNFT} setShowFollowSteps={setShowFollowSteps} /> : <NftMint prediction={prediction} showNftMint={true} walletAddress={walletAddress} mintNft={mintNft} showFollowSteps={showFollowSteps} tokenApprovedForNFT={tokenApprovedForNFT} setShowFollowSteps={setShowFollowSteps} />}
                    {prediction && !prediction?.canPredict && (
                      <p className="prediction-only-view">
                        {" "}
                        This prediction is view only.
                      </p>
                    )}

                    {(prediction?.prediction_type == "RISK-FREE" && prediction?.result == null && is_walletConnected) ?
                      <RiskFreeMessages prediction={prediction} is_wallet_conected={is_walletConnected} extendedNftRemaning={extendedNftRemaning} totalExtensionNFTBalance={totalExtensionNFTBalance} />
                      :
                      null
                    }
                    {prediction?.prediction_type == "RISK-FREE" && prediction?.disclaimer &&
                      <div className="disclamier">
                        <p> <span>Disclaimer:</span>  <SanitizeHTML html={prediction?.disclaimer} /></p>
                      </div>
                    }
                  </div>


                  {prediction?.result != null && (
                    <Row className="congrats-popup-area">
                      <Col md={12} lg={12} xl={12} className="p-0">
                        {console.log(
                          "Risk free won",
                          prediction?.result,
                          prediction?.userOutcome,
                          prediction?.prediction_type,
                          is_walletConnected,
                          prediction?.rewardAmount,
                          formatBalance(prediction?.rewardAmount),
                          parseFloat(formatBalance(prediction?.rewardAmount))
                        )}
                        {(((prediction?.result == prediction?.userOutcome) || (prediction?.result.toString().toLowerCase() == prediction?.userOutcome.toString().toLowerCase())) &&
                          prediction.userAmount > 0 &&
                          prediction?.prediction_type == "PAID") ||
                          (((rightAnswer(prediction)) || (prediction?.result.toString().toLowerCase() == prediction?.userOutcome.toString().toLowerCase())) &&
                            prediction?.isBetOnPrediction &&
                            prediction?.prediction_type == "RISK-FREE" &&
                            is_walletConnected) ? (
                          <div className="congratulation-area">
                            <div className="congratulation-bg-area">
                              <img
                                src={require("../assets/images/gift-box.png")}
                                alt="gift"
                              />
                              <div className="congratulation-content-area">
                                <h5>Congratulations!</h5>
                                <p>
                                  Your prediction scored a win, rewarding you
                                  with{" "}
                                  <span>
                                    {formatBalanceWithDecimals(
                                      prediction?.rewardAmount,
                                      prediction?.decimal
                                    )}{" "}
                                    {prediction?.symbol}.
                                  </span>
                                </p>
                              </div>
                            </div>
                            <h6>Prediction Outcome</h6>

                            <div className="invested-area">
                              <h5>You Invested: </h5>
                              <div className="invested-amount-area">
                                <p>
                                  {parseFloat(
                                    formatBalanceWithDecimals(
                                      prediction?.userAmount,
                                      prediction?.decimal
                                    )
                                  )}{" "}
                                  {prediction?.symbol}
                                </p>
                                <span>
                                  +{" "}
                                  {prediction?.rewardAmount
                                    ? parseFloat(
                                      formatBalanceWithDecimals(
                                        prediction?.rewardAmount,
                                        prediction?.decimal
                                      ) -
                                      formatBalanceWithDecimals(
                                        prediction?.userAmount,
                                        prediction?.decimal
                                      )
                                    ).toFixed(2)
                                    : 0}{" "}
                                  {prediction?.symbol}
                                </span>
                              </div>
                            </div>
                            <hr />
                            <div className="invested-area">
                              <h5>You won: </h5>
                              <div className="invested-amount-area">
                                <p className="won-price">
                                  {parseFloat(
                                    formatBalanceWithDecimals(
                                      prediction?.rewardAmount,
                                      prediction?.decimal
                                    )
                                  )}{" "}
                                  {prediction?.symbol}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {console.log(
                              "Prediction Paid ",
                              prediction?.prediction_type,
                              prediction?.userAmount,
                              prediction?.prediction_type == "PAID" &&
                              prediction?.userAmount > 0,
                              (prediction?.prediction_type == "PAID" &&
                                prediction?.userAmount > 0) ||
                              (prediction?.prediction_type == "RISK-FREE" &&
                                is_walletConnected &&
                                prediction?.result != null &&
                                prediction?.isBetOnPrediction &&
                                (prediction?.user_reward == 0 ||
                                  prediction?.user_reward == undefined))
                            )}
                            {(prediction?.prediction_type == "PAID" &&
                              prediction?.userAmount > 0) ||
                              (prediction?.prediction_type == "RISK-FREE" &&
                                is_walletConnected &&
                                prediction?.result != null &&
                                prediction?.isBetOnPrediction &&
                                (prediction?.user_reward == 0 ||
                                  prediction?.user_reward == undefined)) ? (
                              <div className="prediction-lost-area">
                                <img
                                  src={require("../assets/images/lost.png")}
                                  alt="lost"
                                />
                                <div className="prediction-lost-content">
                                  <h5>Prediction Lost!</h5>
                                  <p>
                                    Don't give up! Your winning streak is just a
                                    prediction away.
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
            <Col md={12} lg={4} xl={4}>
              <div className="prediction-detail-right-outer">
                <div className="prediction-time-top-area">
                  <img src={require("../assets/images/bg-vector-days.png")} />
                  {prediction?.duration ? (
                    <p>1 {prediction?.duration.toLowerCase()}</p>
                  ) : (
                    ""
                  )}
                </div>



                {prediction?.solo_type && <SoloTypeTooltip soloType={prediction?.solo_type} amount={formatBalanceWithDecimals(prediction?.amount, prediction?.decimal)} />}
                {prediction && (prediction.prediction_category == "QUIZ") && (
                  <div className={`common-result ${prediction.prediction_category.toLowerCase()}-listing`}>
                    <p>{capitalizeFirstLetter(prediction.prediction_category.toLowerCase())}</p>
                  </div>
                )}
                <div className="prediction-detail-right">
                  {prediction?.prediction_type == "RISK-FREE" && (
                    <div className={`risk-free-batch risk-free-batch-detail ${prediction?.access_setting == 2 ? 'free-access-tag' : ''}`}>

                      {prediction?.access_setting == 2 ? <p>Free access</p> : <p>Prize pool</p>}
                    </div>
                  )}
                  {prediction?.result != null && (
                    <p className="correct-answer ">
                      Result: <span>{prediction?.result.toString()}</span>{" "}
                    </p>
                  )}

                  <div className="prediction-detail-right-top-heading">
                    {/* <h6>Outcomes</h6> */}
                    {/* betting window */}
                    <div>
                      <div className="betting-area">
                        <p>
                          <span className="betting-text">Betting Window:</span>

                          {betEndTime ? (
                            <Countdown
                              date={moment(betEndTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                              renderer={renderer}
                            >
                              {" "}
                            </Countdown>
                          ) : null}
                        </p>
                      </div>
                      {/* extension window */}
                      {(prediction?.extendedTime > 0) && (
                        <div className="extension-window">
                          <p>
                            <span className="betting-text">Extra Time:</span>

                            {extensionTime ? (
                              <Countdown
                                date={moment(extensionTime).format("YYYY-MM-DD HH:mm:ss")}
                                renderer={renderer}
                              />
                            ) : null}
                          </p>
                        </div>
                      )}

                    </div>

                  </div>

                  {prediction?.prediction_category == "REGULAR" ? (
                    <>
                      <ProgressBar now={truePer} />
                      <div className="progress-bar-info">
                        {prediction?.prediction_type === "RISK-FREE" ? (
                          <>
                            <div className="progess-bar-info-left">
                              <h6>
                                True{" "}
                                <span>
                                  {predictionCount?.count_0
                                    ? predictionCount?.count_0
                                    : "0"}{" "}
                                  bets
                                </span>
                              </h6>
                            </div>
                            <div className="progess-bar-info-right">
                              <h6>
                                False{" "}
                                <span>
                                  {predictionCount?.count_1
                                    ? predictionCount?.count_1
                                    : "0"}{" "}
                                  bets
                                </span>
                              </h6>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="progess-bar-info-left">
                              <h6>
                                True{" "}
                                <span>
                                  (
                                  {predictionCount?.amount_0
                                    ? formatBalance(
                                      predictionCount?.amount_0
                                    )
                                    : "0.00"}{" "}
                                  {prediction?.symbol}{" "}
                                  {predictionCount?.count_0
                                    ? predictionCount?.count_0
                                    : "0"}{" "}
                                  bets)
                                </span>
                              </h6>
                            </div>
                            <div className="progess-bar-info-right">
                              <h6>
                                False{" "}
                                <span>
                                  (
                                  {predictionCount?.amount_1
                                    ? formatBalance(
                                      predictionCount?.amount_1
                                    )
                                    : "0.00"}{" "}
                                  {prediction?.symbol}{" "}
                                  {predictionCount?.count_1
                                    ? predictionCount?.count_1
                                    : "0"}{" "}
                                  bets)
                                </span>
                              </h6>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="prediction-piechart">
                      {console.log("pieChartData", pieChartData)}

                      {prediction && pieChartData && pieChartData.length > 0 ?
                        <PieChart colors={[
                          "#FD8A8A", "#9EA1D4", "#F1F7B5", "#A8D1D1", "#DFEBEB",
                          "#1A1A1D", "#4E4E50", "#6F2232", "#950740", "#C3073F",
                          "#FF5733", "#C70039", "#900C3F", "#581845", "#FFC300",
                          "#FF5733", "#C70039", "#900C3F", "#581845", "#FFC300",
                          "#DAF7A6", "#FFC300", "#FF5733", "#C70039", "#900C3F",
                          "#FF5733", "#C70039", "#900C3F", "#581845", "#DAF7A6",
                          "#FFC300", "#FF5733", "#C70039", "#900C3F", "#581845",
                          "#FFC300", "#FF5733", "#C70039", "#900C3F", "#581845",
                          "#DAF7A6", "#FFC300", "#FF5733", "#C70039", "#900C3F",
                          "#581845", "#DAF7A6", "#FFC300", "#FF5733", "#C70039",
                          "#FF4500", "#32CD32", "#FFD700", "#87CEEB", "#6A5ACD",
                          "#D2691E", "#FF69B4", "#8A2BE2", "#00CED1", "#00FA9A",
                          "#FF6347", "#4682B4", "#DDA0DD", "#B0C4DE", "#7B68EE",
                          "#E9967A", "#48D1CC", "#C71585", "#808080", "#D2B48C",
                          "#00FF7F", "#DB7093", "#FFDAB9", "#CD5C5C", "#FFE4C4",
                          "#FF1493", "#8B0000", "#FF8C00", "#6495ED", "#20B2AA",
                          "#00BFFF", "#FFA500", "#FF00FF", "#000080", "#800080",
                          "#2E8B57", "#B22222", "#A52A2A", "#DEB887", "#F4A460",
                          "#DA70D6", "#5F9EA0", "#FFFAFA", "#E0FFFF", "#FAEBD7",
                          "#D3D3D3", "#00FFFF", "#FFE4E1", "#F08080", "#FA8072",
                          "#7FFF00", "#D2691E", "#FF7F50", "#6495ED", "#FFF8DC",
                          "#DC143C", "#00FFFF", "#00008B", "#008B8B", "#B8860B",
                          "#A9A9A9", "#006400", "#BDB76B", "#8B008B", "#556B2F",
                          "#FF8C00", "#9932CC", "#8B0000", "#E9967A", "#8FBC8F",
                          "#483D8B", "#2F4F4F", "#00CED1", "#9400D3", "#FF1493",
                          "#00BFFF", "#696969", "#1E90FF", "#B22222", "#FFFAF0",
                          "#228B22", "#FF00FF", "#DCDCDC", "#F8F8FF", "#FFD700",
                          "#DAA520", "#808080", "#ADFF2F", "#F0FFF0", "#FF69B4",
                          "#CD5C5C", "#4B0082", "#FFFFF0", "#F0E68C", "#E6E6FA",
                          "#FFF0F5", "#7CFC00", "#FFFACD", "#ADD8E6", "#F08080",
                          "#E0FFFF", "#FAFAD2", "#D3D3D3", "#90EE90", "#FFB6C1",
                          "#FFA07A", "#20B2AA", "#87CEFA", "#778899", "#B0C4DE",
                          "#FFFFE0", "#32CD32", "#FAF0E6", "#FF00FF", "#800000",
                          "#66CDAA", "#0000CD", "#BA55D3", "#9370DB", "#3CB371",
                          "#7B68EE", "#00FA9A", "#48D1CC", "#C71585", "#191970",
                          "#F5FFFA", "#FFE4E1", "#FFE4B5", "#FFDEAD", "#000080",
                          "#FDF5E6", "#808000", "#6B8E23", "#FFA500", "#FF4500",
                          "#DA70D6", "#EEE8AA", "#98FB98", "#AFEEEE", "#DB7093",
                          "#FFEFD5", "#FFDAB9", "#CD853F", "#FFC0CB", "#DDA0DD",
                          "#B0E0E6", "#800080", "#663399", "#FF0000", "#BC8F8F",
                          "#4169E1", "#8B4513", "#FA8072", "#F4A460", "#2E8B57"
                        ]}
                          series={[
                            {
                              data: pieChartData,
                            },
                          ]}
                          {...sizing}
                        />
                        :
                        null
                      }

                      {prediction && pieChartData && pieChartData.length == 0 ?
                        <PieChart
                          colors={[
                            "#a1a1a145"
                          ]}
                          series={[
                            {
                              data: [{ id: 0, value: "00.0000001", label: "No Bet Yet" }],
                            },
                          ]}
                          {...sizing}
                        />
                        :
                        null
                      }

                    </div>
                  )}

                  {prediction?.prediction_type === "RISK-FREE" ? (
                    <p className="progress-total">
                      Total :
                      <span>
                        {predictionCount?.totalCount
                          ? predictionCount?.totalCount
                          : "0"}{" "}

                        {predictionCount?.totalCount
                          ? predictionCount?.totalCount == 1 ? "bet" : "bets"
                          : "bets"}
                      </span>
                    </p>
                  ) : (
                    <p className="progress-total">
                      Total :
                      <span>
                        <b>
                          {predictionCount?.totalAmount
                            ? formatBalance(predictionCount?.totalAmount)
                            : "0.00"}
                        </b>{" "}
                        {prediction?.symbol}
                      </span>
                      (
                      {predictionCount?.totalCount
                        ? predictionCount?.totalCount
                        : "0"}{" "}
                      bets)
                    </p>
                  )}

                  <div className="betting-window">
                    <h6>Prediction Timeframe :</h6>
                    <div className="betting-window-content">
                      <div className="start-end-date">
                        <div className="start-date">
                          <p>Start Date & Time </p>
                        </div>
                        <div className="end-date">
                          <p>End Date & Time</p>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="start-end-date">
                        <div className="start-date-info">
                          <p>
                            {moment(prediction?.startDateTime).format(
                              "MMM DD, HH:mm A"
                            )}
                          </p>

                          <p>
                            {" "}
                            {moment
                              .utc(prediction?.startDateTime)
                              .format("MMM DD, HH:mm A")}{" "}
                            <span>(UTC)</span>
                          </p>
                        </div>
                        <div className="end-date-info">
                          <p>
                            {moment(prediction?.endDateTime).format(
                              "MMM DD, HH:mm A"
                            )}{" "}
                          </p>
                          <p>
                            {" "}
                            {moment
                              .utc(prediction?.endDateTime)
                              .format("MMM DD, HH:mm A")}{" "}
                            <span>(UTC)</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="leader-board">
                    <div className="leader-board-top">
                      <h6>Prediction Leader-board </h6>
                      {console.log(leaderboard)}
                      {leaderboard?.leaderboardTotalCount > 4 &&
                        limit != leaderboard?.leaderboardTotalCount && (
                          <p
                            onClick={() =>
                              viewMore(leaderboard.leaderboardTotalCount)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            View More
                          </p>
                        )}
                    </div>
                    <div className="leader-board-content-area">
                      {leaderboard?.leaderboard &&
                        leaderboard.leaderboard?.length > 0 ? (
                        leaderboard.leaderboard.map((data, index) => {
                          return (
                            <>
                              <div className="leader-board-content" key={index}>
                                <h5>{formatAddress(data.user)}</h5>
                                <p>
                                  {prediction?.prediction_type == "PAID" ? (
                                    <>
                                      <span>
                                        {formatBalanceWithDecimals(
                                          data.totalAmount,
                                          prediction?.decimal
                                        )}{" "}
                                        {prediction?.symbol}
                                      </span>
                                      <span>
                                        ({data.prediction.toString()})
                                      </span>
                                    </>
                                  ) : (
                                    <span>{data.prediction.toString()}</span>
                                  )}
                                </p>
                              </div>{" "}
                            </>
                          );
                        })
                      ) : (
                        <>
                          <div className="no-data-area">
                            <img
                              src={require("../assets/images/no-data.gif")}
                              alt="img"
                            />
                            <p> No Data Found</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!walletAddress?.accounts && walletAddress ? <SubscribeForm contractAddress={address?.address} prediction={prediction} /> : ""}

            </Col>
          </Row>
        </Container >
      </section >
      <Modal
        show={showPlaceBetSteps}
        backdrop="static"
        onHide={handleClosePlaceBetSteps}
        centered
        className="connect-wallet-box follow-steps-popup"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mt-0">Follow Steps </h3>
          {window.innerWidth <= 768 ? (
            <AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className="follow-steps-ad-mid" />
          ) : (
            <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className="follow-steps-ad-mid" />
          )}

          <div className="step-area">
            <div className="steps-left-area">
              {tokenApproved ? <h2>1</h2> : <div class="loader"></div>}
            </div>
            <div className="steps-content">
              <h6>Approving USDC</h6>
              <p>Approving USDC</p>
            </div>
          </div>
          <div className="step-area">
            <div className="steps-left-area">
              {tokenApproved ? <div class="loader"></div> : <h2>2</h2>}
            </div>
            <div className="steps-content">
              <h6>Predicting</h6>
              <p>Send transaction to predict USDC</p>
            </div>
          </div>

        </Modal.Body>
      </Modal >

      <Modal
        show={showPlaceBetRiskFreeSteps}
        backdrop="static"
        onHide={handleClosePlaceBetRiskFreeSteps}
        centered
        className="connect-wallet-box follow-steps-popup"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mt-0">Follow Steps </h3>
          {console.log("window.innerWidth::::::::::::::", window.innerWidth)}
          <div className={`follow-steps-ad-mid under-modal-div ${showPlaceBetRiskFreeSteps ? "modal-block" : ""}`}>
            {window.innerWidth <= 768 ? (
              <AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className="follow-steps-ad-mid" />
            ) : (
              <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className="follow-steps-ad-mid" />
            )}

          </div>
          <div className="step-area">
            <div className="steps-left-area">
              {tokenApproved ? <h2>1</h2> : <div class="loader"></div>}
            </div>
            <div className="steps-content">
              <h6>Predicting</h6>
              <p>Send transaction to predict</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {
        (prediction?.data_provider == "solo-predictions" || prediction?.data_provider == "multi-choice-predictions") && (
          <Modal show={showNft} onHide={handleCloseNft} className="connect-wallet-box login-popup nft-types-popup" centered>
            <Modal.Header closeButton>
              <Modal.Title>Time Extension
                <button type="button" class="btn-close" aria-label="Close"></button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>The betting window for this prediction has closed and it is now on Extra Time. Buy more time on this predictions page to bet now and increase your chance of winning</p>
              {prediction?.data_provider == "solo-predictions" ? (
                <ul>
                  <li onClick={() => mintNFTByMintId(4)}>
                    <a
                    // href="https://1-bet-solo-time-extension-nft.nfts2.me/" target="_blank" rel="noopener noreferrer"
                    >
                      Solo Time Extension NFT <p>1 bet</p>
                    </a>
                  </li>
                  <li onClick={() => { mintNFTByMintId(5) }}>
                    <a
                    // href="https://3-bets-solo-time-extension-nft.nfts2.me/" target="_blank" rel="noopener noreferrer"
                    >
                      Solo Time Extension NFT <p>3 bets</p>
                    </a>
                  </li>
                  <li onClick={() => { mintNFTByMintId(6) }}>
                    <a
                    // href="https://10-bets-solo-time-extension-nft.nfts2.me/" target="_blank" rel="noopener noreferrer"
                    >
                      Solo Pearl Time Extension NFT <p>10 bets</p>
                    </a>
                  </li>
                </ul>

              ) : (
                prediction?.data_provider == "multi-choice-predictions" && (
                  <ul>
                    <li onClick={() => { mintNFTByMintId(7) }}>
                      <a
                      // href="https://1-bet-multichoice-time-nft.nfts2.me/" target="_blank" rel="noopener noreferrer"
                      >
                        Multi-choice Time Extension NFT  <p>1 bet</p>
                      </a>
                    </li>
                    <li onClick={() => { mintNFTByMintId(8) }}>
                      <a
                      // href="https://20-bets-multichoice-time-nft.nfts2.me/" target="_blank" rel="noopener noreferrer"
                      >
                        Multi-choice Time Extension NFT <p>20 bets</p>
                      </a>
                    </li>
                  </ul>
                )
              )}
              <div className="time-extension-info-area">
                <h6>Extension Time:</h6>
                <ul>
                  {prediction?.data_provider == "solo-predictions" ? <>
                    <li>+480 min (8 hours) for Solo Pearl Predictions</li>
                    <li>+12 hours for Solo Sapphires, Rubies, Emeralds & Diamonds</li>
                  </> : prediction?.data_provider == "multi-choice-predictions" &&
                  <>
                    <li>+20 min for Multichoice Pearls Predictions</li>
                    <li>+30 min for Hourly Multichoice Sapphires</li>
                    <li>+12 hours for Daily Multichoice Sapphires, Rubies, Emeralds & Diamonds</li>
                  </>}
                </ul>
              </div>
            </Modal.Body>
          </Modal>
        )
      }

    </>
  );
};
export default PredictionDetail;
