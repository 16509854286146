import React, { useState } from "react";
import Web3 from "web3";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const TransferFunds = () => {
  const [privateKey, setPrivateKey] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [network, setNetwork] = useState("amoy");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    if (!Web3.utils.isAddress(receiverAddress)) {
      setErrorMessage("Invalid receiver address.");
      return;
    }

    try {
      const rpcUrls = {
        amoy: "https://polygon-amoy-bor-rpc.publicnode.com",
        mainnet: "https://polygon-bor-rpc.publicnode.com",
      };

      const web3 = new Web3(rpcUrls[network]);

      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      web3.eth.accounts.wallet.add(account);
      web3.eth.defaultAccount = account.address;

      const gasPrice = await web3.eth.getGasPrice();
      const adjustedGasPrice = web3.utils.toWei(
        (2 * parseFloat(web3.utils.fromWei(gasPrice, "gwei"))).toString(),
        "gwei"
      );
      const maxPriorityFeePerGas = web3.utils.toWei("30", "gwei");

      const nonce = await web3.eth.getTransactionCount(account.address, "latest");

      const gasLimit = await web3.eth.estimateGas({
        from: account.address,
        to: receiverAddress,
        value: web3.utils.toWei(amount, "ether"),
      });

      const tx = {
        from: account.address,
        to: receiverAddress,
        value: web3.utils.toWei(amount, "ether"),
        gas: gasLimit,
        maxPriorityFeePerGas: maxPriorityFeePerGas,
        maxFeePerGas: adjustedGasPrice,
        nonce: nonce,
      };

      const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);

      const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

      setSuccessMessage(`Transaction successful! TX Hash: ${receipt.transactionHash}`);
    } catch (error) {
      console.error("Error sending transaction:", error);
      setErrorMessage("Failed to send transaction. Please try again.");
    }
  };


  const handleSendTokenSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    if (!Web3.utils.isAddress(receiverAddress)) {
      setErrorMessage("Invalid receiver address.");
      return;
    }

    try {
      const rpcUrls = {
        amoy: "https://polygon-amoy-bor-rpc.publicnode.com",
        mainnet: "https://polygon-bor-rpc.publicnode.com",
      };

      const web3 = new Web3(rpcUrls[network]);

      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      web3.eth.accounts.wallet.add(account);
      web3.eth.defaultAccount = account.address;

      // Token Contract Address and ABI
      const tokenAbi = [
        // ERC-20 transfer function ABI
        {
          constant: false,
          inputs: [
            { name: "_to", type: "address" },
            { name: "_value", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ name: "", type: "bool" }],
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "decimals",
          outputs: [{ name: "", type: "uint8" }],
          type: "function",
        },
      ];

      const tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);

      const gasPrice = await web3.eth.getGasPrice();
      const adjustedGasPrice = web3.utils.toWei((2 * parseFloat(web3.utils.fromWei(gasPrice, "gwei"))).toString(), "gwei");
      const maxPriorityFeePerGas = web3.utils.toWei("30", "gwei");

      const nonce = await web3.eth.getTransactionCount(account.address, "latest");


      // Convert token amount to smallest unit (e.g., wei)
      const tokenDecimals = await tokenContract.methods.decimals().call();

      const decimalsFactor = Number(10 ** Number(tokenDecimals));
      const tokenAmount = Number(amount) * decimalsFactor;
      // Encode the ABI
      const expandedNumber = tokenAmount.toLocaleString('fullwide', { useGrouping: false });

      const data = await tokenContract.methods.transfer(receiverAddress, expandedNumber).encodeABI();

      // Estimate Gas using the encoded data
      const gasLimit = await web3.eth.estimateGas({
        from: account.address,
        to: tokenAddress,
        data: data,
      });

      const tx = {
        to: tokenAddress,
        data: data,
        gas: gasLimit,
        value: 0,
        maxPriorityFeePerGas: maxPriorityFeePerGas,
        maxFeePerGas: adjustedGasPrice,
        nonce: nonce,
      };

      const signedTx = await web3.eth.accounts.signTransaction(tx, privateKey);

      const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

      setSuccessMessage(`Token transfer successful! TX Hash: ${receipt.transactionHash}`);
    } catch (error) {
      console.log("Error sending transaction:", error);
      setErrorMessage("Failed to send tokens. Please try again.");
    }
  };


  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Network:</Form.Label>
            <Form.Select aria-label="Select"
              as="select"
              value={network}
              onChange={(e) => setNetwork(e.target.value)}
              required>
              <option value="amoy">Polygon Amoy (Testnet)</option>
              <option value="mainnet">Polygon Mainnet</option>
            </Form.Select>

          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Private Key:</Form.Label>
            <Form.Control
              type="password"
              value={privateKey}
              onChange={(e) => setPrivateKey(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="card-title text-center mb-4">Send Balance</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Receiver Address:</Form.Label>
                  <Form.Control
                    type="text"
                    value={receiverAddress}
                    onChange={(e) => setReceiverAddress(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Amount (MATIC):</Form.Label>
                  <Form.Control
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                  Send Balance
                </Button>
              </Form>
              {successMessage && (
                <Alert variant="success" className="mt-3">
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert variant="danger" className="mt-3">
                  {errorMessage}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="card-title text-center mb-4">Send Token Amount</h2>
              <Form onSubmit={handleSendTokenSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Token Address:</Form.Label>
                  <Form.Control
                    type="text"
                    value={tokenAddress}
                    onChange={(e) => setTokenAddress(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Amount (Token):</Form.Label>
                  <Form.Control
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100">
                  Send Token
                </Button>
              </Form>
              {successMessage && (
                <Alert variant="success" className="mt-3">
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert variant="danger" className="mt-3">
                  {errorMessage}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

};
export default TransferFunds;